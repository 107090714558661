// BookCard.js
import React, { useEffect, useState } from "react";
import "./BookCard.css"; // Optional: Add specific styles for the card component
import { fetchCityName } from "../../libs/formateLocation";
import moment from "moment/moment";
import { imagePlaceHolder } from "../../libs/PlaceHolderImage";
import { HeartIcon as OutlineHeartIcon } from "@heroicons/react/24/outline";
import { HeartIcon as SolidHeartIcon } from "@heroicons/react/24/solid";
import { GetUser, Updatefavourite } from "../../Services/Auth/Auth";
// import { UseUser } from "../../Context/UserContext";
import { useNavigate } from "react-router-dom";
import PositionedSnackbar from "../Snackbar/Snackbar";
const BookCard = ({
  book,
  backgroundColor,
  ButtonComponent,
  user,
  setUser,
  onUnfavorite = () => {},
}) => {
  const [city, setCity] = useState("");
  const [isFavourite, setIsFavourite] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      const Favourite = user.favouriteBooks.some(
        (favBook) => favBook._id === book._id
      );
      if (Favourite !== isFavourite) {
        // Only update if necessary
        setIsFavourite(Favourite);
      }
    }
  }, [user, book._id, isFavourite]);

  useEffect(() => {
    function fetchCity() {
      if (book) {
        fetchCityName(book.location.coordinates, setCity);
      }
    }
    fetchCity();
  });
  const timeAgo = moment(book.createdAt).fromNow();
  const handleHeartToggle = async () => {
    if (user) {
      const res = await Updatefavourite({
        book_id: book._id,
      });
      if (res.status === 200) {
        setSnackbarMessage(res.data.message);
        setSnackbarOpen(true);
        const token = localStorage.getItem("token");
        const parseToken = JSON.parse(token);
        const response = await GetUser(parseToken);
        if (response.status === 200) {
          localStorage.setItem("user", JSON.stringify(response.data.data));
          setUser(response.data.data);
          if (
            !response.data.data.favouriteBooks.some(
              (favBook) => favBook._id === book._id
            )
          ) {
            onUnfavorite(book._id); // Call onUnfavorite if book is no longer in favorites
          }
        } else if (response.status === 401) {
          localStorage.removeItem("user");
          localStorage.removeItem("token");
          navigate("/login");
        }
        // setIsHeartFilled((prev) => !prev);
      } else if (res.status === 401) {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        navigate("/login");
      } else {
        setSnackbarMessage("Error occurred during favorite");
        setSnackbarOpen(true);
        setSnackbarSeverity("error");
      }
    } else {
      navigate("/login");
    }
  };
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <div>
      <div
        className="book-carousel-card-container m-3"
        style={{ backgroundColor }}
      >
        <div
          className="book-image d-flex align-items-center justify-content-center p-4"
          style={{ position: "relative" }}
        >
          <button
            onClick={handleHeartToggle}
            style={{
              background: "none",
              border: "none",
              cursor: "pointer",
              position: "absolute",
              top: "2.5rem",
              right: "3rem",
            }}
          >
            {isFavourite ? (
              <SolidHeartIcon className="h-8 w-8 text-red-500" />
            ) : (
              <OutlineHeartIcon className="h-8 w-8 border-red-500" />
            )}
          </button>
          <img src={book.images[0] || imagePlaceHolder} alt="Book Cover" />
        </div>
        <div className="book-description p-4 d-flex flex-column gap-2">
          <h5 title={book.title} className="m-0 p-0 fs-4 d-inline truncate">
            {book.title}
          </h5>

          <div className="first-row d-flex justify-content-between align-items-center">
            <p className="m-0 p-0 fw-bold fs-5">Rs.{book.price}</p>
            {book.isExchangeable && (
              <div
                class="  d-flex justify-content-end"
                style={{ color: "rgba(0, 154, 240, 1)" }}
              >
                Exchangeable
              </div>
            )}
          </div>
          <div className="second-row d-flex justify-content-between align-items-center">
            <p className="m-0 p-0">
              <i
                className="fa-solid fa-location-dot me-2"
                style={{ color: "#000000" }}
              ></i>
              {city}
            </p>
            <p className="m-0 p-0">{timeAgo}</p>
          </div>
          {ButtonComponent && (
            <ButtonComponent
              bookId={book._id}
              backgroundColor={backgroundColor}
              book={book}
            />
          )}
        </div>
      </div>
      <PositionedSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        handleClose={handleCloseSnackbar}
      />
    </div>
  );
};

export default BookCard;
