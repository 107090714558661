import React from "react";
import "./AddImages.css";

const ImageRow = ({ previews, setPreviews }) => {
  const handleImageChange = (event) => {
    const files = Array.from(event.target.files); // Convert FileList to array
    setPreviews((pre) => [...pre, ...files]); // Store the file objects directly
  };
  const handleRemoveImage = (indexToRemove) => {
    setPreviews((pre) => pre.filter((_, index) => index !== indexToRemove));
  };
  return (
    <>
      {previews.length === 0 ? (
        <div className="upload-images-container field-container rounded-4 d-flex flex-column align-items-center justify-content-around gap-3 py-3 my-3">
          <img src={require("../../Assets/upload-image.png")} alt="" />
          <div className="add-image-button d-flex flex-column align-items-center justify-content-center">
            <input
              type="file"
              style={{ display: "none" }}
              id="file-input"
              accept="image/*"
              multiple
              onChange={handleImageChange}
            />
            <label className="rounded-3 py-2 px-4 fs-5" htmlFor="file-input">
              Add Images
            </label>
          </div>
        </div>
      ) : (
        <div className="upload-images-container field-container rounded-4">
          {/* <div className="p-1">
            <div className="row"> */}
          <div className="images d-flex flex-wrap align-items-center justify-content-start">
            {previews.map((preview, index) => (
              <div key={index} className="position-relative m-2">
                <img
                  src={URL.createObjectURL(preview)}
                  alt={`Selected ${index}`}
                  className="rounded-3 responsive-img"
                  style={{
                    height: "150px",
                    width: "100px",
                    objectFit: "cover",
                  }}
                />
                {/* Cross button to remove image */}
                <button
                  onClick={() => handleRemoveImage(index)}
                  className="remove-image-btn position-absolute top-0 end-0 bg-white text-black "
                  style={{
                    border: "none",
                    cursor: "pointer",
                    width: "24px",
                    height: "24px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  &times;
                </button>
              </div>
            ))}
            <div className="p-2">
              <div className="d-flex flex-column align-items-center justify-content-center">
                <input
                  type="file"
                  className="form-control"
                  accept="image/*"
                  onChange={handleImageChange}
                  multiple
                  id="file-input"
                  style={{ minWidth: "150px", display: "none" }}
                />
                <label
                  className="rounded-3 py-2 px-4 fs-5"
                  htmlFor="file-input"
                >
                  <i
                    className="fa-solid fa-plus add-image-after-button p-3"
                    style={{ color: "#ffffff" }}
                  ></i>
                </label>
              </div>
            </div>
          </div>
          {/* </div>
          </div> */}
        </div>
      )}
    </>
  );
};

export default ImageRow;
