import instance from "../instant";
const GetCategory = async () => {
    try {
        const response = await instance.get('category');
        return response;
    } catch (error) {
        return error;
    }
};


export { GetCategory};