import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import "./ForgotResetPassword.css";
import { resetPassword } from "../../Services/Auth/Auth";
import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import Loader from "../../Components/Loader/Loader";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const ResetPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const password = watch("password");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");
  const [loading, setLoading] = useState(false);

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const onSubmit = async (data) => {
    if (data.password !== data.confirmPassword) {
      setSnackbarMessage("Passwords do not match");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }
    if (!data.password || !data.confirmPassword) {
      setSnackbarMessage("Password is required");
      setSnackbarOpen(true);
      setSnackbarSeverity("error");
      return;
    }
    try {
      setLoading(true);
      // Call ForgotPassword and pass the email
      const res = await resetPassword(token, {
        password: data.password,
      });

      if (res.status === 200) {
        const message = res.data.message;
        setSnackbarMessage(message); // Set the snackbar message
        setSnackbarOpen(true);
        setSnackbarSeverity("success");

        setTimeout(() => {
          navigate("/login");
        }, 1000);
        setLoading(false);
      } else {
        // Handle error
        setSnackbarMessage("Failed to Reset Password."); // Set the snackbar message
        setSnackbarOpen(true);
        setSnackbarSeverity("error");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error sending email:", error);
      setLoading(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="sign-up-bg d-flex align-items-center justify-content-center">
          <div className="sign-up-container d-flex align-items-center justify-content-center flex-column p-4">
            <div className="create-new-account-text pt-2">
              <p className="welcome-back text-center m-0 p-0 fs-4 fw-bold">
                Resetting Password
              </p>
              <p className="text-center m-0 p-0 mb-3">
                Reset your password here
              </p>
            </div>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="create-new-account-form"
            >
              {/* New Password Field */}
              <div className="input-group d-flex flex-column mt-2">
                <div className="input-container">
                  <i
                    className="fa-solid fa-lock fa-xl"
                    style={{ color: "#1877f2" }}
                  ></i>
                  <input
                    className={`form-control password-input rounded-4 ${
                      errors.password ? "is-invalid" : ""
                    }`}
                    type={showPassword ? "text" : "password"}
                    placeholder="Enter New Password"
                    {...register("password", {
                      required: "Password is required",
                      minLength: {
                        value: 6,
                        message: "Password must be at least 6 characters",
                      },
                    })}
                  />
                  <i
                    className={`the-eye-button fa ${
                      showPassword ? "fa-eye" : "fa-eye-slash"
                    }`}
                    onClick={togglePasswordVisibility}
                  ></i>
                </div>
                {errors.password ? (
                  <span className="error-message text-danger m-0 ms-1 mb-2">
                    {errors.password.message}
                  </span>
                ) : (
                  <div className="my-1 py-1"></div>
                )}
              </div>

              {/* Confirm Password Field */}
              <div className="input-group d-flex flex-column">
                <div className="input-container">
                  <i
                    className="fa-solid fa-lock fa-xl"
                    style={{ color: "#1877f2" }}
                  ></i>
                  <input
                    className={`form-control password-input rounded-4 ${
                      errors.confirmPassword ? "is-invalid" : ""
                    }`}
                    type={showConfirmPassword ? "text" : "password"}
                    placeholder="Confirm Password"
                    {...register("confirmPassword", {
                      required: "Please confirm your password",
                      validate: (value) =>
                        value === password || "Passwords do not match",
                    })}
                  />
                  <i
                    className={`the-eye-button fa ${
                      showConfirmPassword ? "fa-eye" : "fa-eye-slash"
                    }`}
                    onClick={toggleConfirmPasswordVisibility}
                  ></i>
                </div>
                {errors.confirmPassword ? (
                  <span className="error-message text-danger m-0 ms-1 mb-2">
                    {errors.confirmPassword.message}
                  </span>
                ) : (
                  <div className="my-2 py-1"></div>
                )}
              </div>

              {/* Update Button */}
              <div className="create-account-button w-100 pb-1 ">
                <button
                  type="submit"
                  className="submit-button btn btn-primary w-100 border-0 fw-bold fs-5 my-2 rounded-3 py-2"
                >
                  <p className="p-0 m-0">Reset</p>
                </button>
              </div>
            </form>
          </div>

          <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </div>
      )}
    </>
  );
};

export default ResetPassword;
