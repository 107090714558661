import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./Footer.css";
import Navbar from "react-bootstrap/Navbar";

const Footer = () => {
  const location = useLocation();

  // Routes where the footer should be hidden
  const hiddenFooterRoutes = [
    "/sign-up-with",
    "/create-new-account",
    "/sell-book-form",
    "/demand-book-form",
    "/sell-tutor-services-form",
    "/edit-profile",
    "/login",
    "/reset-password",
    "/forgot-password",
  ];

  // Check if the current location matches any of the routes to hide the footer
  if (hiddenFooterRoutes.includes(location.pathname)) {
    return null; // Don't render the footer
  }

  return (
    <div className="footer-container">
      {/* Footer content remains unchanged */}
      <div className="footer-section-1 d-flex flex-column gap-4 gap-sm-2">
        <div className="footer-logo d-flex align-items-center justify-content-start gap-3">
          <Navbar.Brand
            href="/"
            className="d-flex align-items-center justify-content-between p-0 m-0 text-decoration-none"
          >
            <img
              className="navbar-logo"
              src={require("../../Assets/books-brary-logo.png")}
              alt="Books Brary"
            />
            <h3 className="logo-text m-0 p-0 pt-1 ps-2 fs-4">Books Brary</h3>
          </Navbar.Brand>
        </div>
        <p className="m-0 text-size-medium">
          We are a community of readers offering friendly service and
          unforgettable book experiences.
        </p>
        <div className="footer-social-media-links d-flex gap-2">
          <a
            href="https://www.facebook.com/p/Max-Brary-61564973555861/"
            target="_blank"
            rel="noreferrer"
          >
            <i
              className="fa-brands fa-facebook d-flex align-items-center justify-content-center p-2"
              style={{ color: "#ffffff" }}
            ></i>
          </a>
          <a href="https://www.twitter.com" target="_blank" rel="noreferrer">
            <i
              className="fa-brands fa-twitter d-flex align-items-center justify-content-center p-2"
              style={{ color: "#ffffff" }}
            ></i>
          </a>
          <a href="https://www.instagram.com" target="_blank" rel="noreferrer">
            <i
              className="fa-brands fa-instagram d-flex align-items-center justify-content-center p-2"
              style={{ color: "#ffffff" }}
            ></i>
          </a>
        </div>
      </div>
      <div className="footer-section-2 pt-1">
        <div className="footer-services m-0 d-flex flex-column gap-4 gap-sm-2">
          <h4 className="m-0 text-size-large">Services</h4>
          <ul className="m-0 fw-medium flex flex-wrap flex-sm-column text-size-medium ps-3 gap-6 sm:gap-2">
            <li className="text-nowrap">
              <Link to="/selling-books-library">Library</Link>
            </li>
            <li className="text-nowrap">
              <Link to="/tutor-library">Tutor</Link>
            </li>
            <li className="text-nowrap">
              <Link to="/about-us">About Us</Link>
            </li>
            <li className="text-nowrap">
              <Link to="/privacy-policy">Privacy Policy</Link>
            </li>
          </ul>
        </div>
      </div>

      <div className="footer-section-3 pt-1">
        <div className="footer-contact d-flex flex-column gap-4 gap-sm-3">
          <h4 className="m-0 text-size-large">Contact Us</h4>
          <div className="d-flex flex-column gap-2">
            <div className="contact-number fw-medium d-flex gap-3 align-items-center justify-content-start">
              <i
                className="fa-solid fa-phone contact-logo p-3 d-flex align-items-center justify-content-center"
                style={{ color: "#ffffff" }}
              ></i>
              <p className="m-0 fw-medium text-size-medium">
                <a href="tel:+923110507800">+923110507800</a>
              </p>
            </div>
            <div className="contact-address fw-medium d-flex gap-3 align-items-center justify-content-start">
              <i
                className="fa-solid fa-location-dot p-3 contact-logo d-flex align-items-center justify-content-center"
                style={{ color: "#ffffff" }}
              ></i>
              <p className="m-0 fw-medium text-size-medium">
                Main Morgah Mor, Near Ayub National Park, Rawalpindi Pakistan
              </p>
            </div>
            <div className="contact-number fw-medium d-flex gap-3 align-items-center justify-content-start">
              <i
                className="fa-solid fa-envelope contact-logo p-3 d-flex align-items-center justify-content-center"
                style={{ color: "#ffffff" }}
              ></i>
              <p className="m-0 fw-medium text-size-medium">
                <a href="mailto:booksbraryofficial@gmail.com">
                  booksbraryofficial@gmail.com
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
