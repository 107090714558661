// src/components/TypesCarousel.js

import React, { useState, useEffect } from "react";
import "./TypesCarousel.css";
import historyLogo from "../../Assets/type-1.png";
import { GetCategory } from "../../Services/Category/Category";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { UseUser } from "../../Context/UserContext";
import { useNavigate } from "react-router-dom";
export default function TypesCarousel() {
  const [bookTypes, setBookTypes] = useState([]);
  const { setSelectedCategory } = UseUser();
  const navigation = useNavigate();
  useEffect(() => {
    async function GetCategories() {
      const fetchCategory = await GetCategory();
      if (fetchCategory.status === 200) {
        const categories = fetchCategory.data.data;
        setBookTypes(categories);
      }
    }
    GetCategories();
  }, []);

  const bookTypeTemplate = (bookType) => {
    return (
      <div
        className=" p-3"
        style={{ width: "100%" }}
        key={bookType._id}
        onClick={() => {
          navigation("/selling-books-library");
          setSelectedCategory(bookType.title);
        }}
      >
        <div className=" category w-100" key={bookType._id}>
          <div className="book-type-card w-100  d-flex align-items-center justify-content-around flex-column">
            <img
              src={bookType.icon !== "" ? bookType.icon : historyLogo}
              alt={`${bookType.type} logo`}
              className="book-type-logo"
            />
            <div className="book-type-info d-flex align-items-center justify-content-around flex-column">
              <h5 className="book-type-name">{bookType.title}</h5>
              {/* <p className="book-type-total">Total Items: {bookType.totalItems}</p> */}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 1500,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1650,
        settings: { slidesToShow: 4 },
      },
      {
        breakpoint: 1350,
        settings: { slidesToShow: 4 },
      },
      {
        breakpoint: 1010,
        settings: { slidesToShow: 2 },
      },
      {
        breakpoint: 680,
        settings: { slidesToShow: 1 },
      },
    ],
  };

  return (
    <div className="book-carousel-card">
      <Slider {...settings}>{bookTypes.map(bookTypeTemplate)}</Slider>
    </div>
  );
}

// import { Carousel } from "primereact/carousel";
// import "./TypesCarousel.css";

// export default function TypesCarousel() {

//   const responsiveOptions = [
//     {
//       breakpoint: "2500px",
//       numVisible: 3,
//       numScroll: 1,
//     },
//     {
//       breakpoint: "1400px",
//       numVisible: 2,
//       numScroll: 1,
//     },
//     {
//       breakpoint: "880px",
//       numVisible: 1,
//       numScroll: 1,
//     },
//   ];

//   const bookTemplate = (book) => {

//     return (
//       <div className="types-carousel">
//             <img src={require("../../Assets/type-1.svg")} alt="" />
//       </div>
//     );
//   };

//   return (
//     <div className="book-carousel-card">
//       <Carousel
//         value={books}
//         numVisible={3}
//         numScroll={1}
//         responsiveOptions={responsiveOptions}
//         className="custom-carousel"
//         circular
//         autoplayInterval={4000}
//         itemTemplate={bookTemplate}
//       />
//     </div>
//   );
// }
