import React, { useState, useEffect } from "react";
import TutorCard from "../TutorCard/TutorCard";
import "./TutorCarousel.css";
import { GetAllServices } from "../../Services/tutorServices/Services";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export default function TutorCarousel() {
  const [tutors, setTutors] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const fetchedTutors = await GetAllServices();
      if (fetchedTutors.status === 200) {
        const TutorsData = fetchedTutors.data.data;
        setTutors(TutorsData);
      }
    }
    fetchData();
  }, []);

  const tutorTemplate = (tutor) => {
    return (
      <div className=" p-3" style={{ width: "100%" }}>
        <div className=" category w-100">
          {" "}
          <TutorCard key={tutor._id} tutor={tutor} />
        </div>
      </div>
    );
  };

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: false,
    speed: 500,
    autoplaySpeed: 1500,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1920,
        settings: { slidesToShow: 4 },
      },

      {
        breakpoint: 1400,
        settings: { slidesToShow: 3 },
      },

      {
        breakpoint: 991,
        settings: { slidesToShow: 2 },
      },
      {
        breakpoint: 640,
        settings: { slidesToShow: 1 },
      },
    ],
  };

  return (
    <div className="book-carousel-card">
      <Slider {...settings}>{tutors.map(tutorTemplate)}</Slider>
    </div>
  );
}
