import React, { useState } from "react";
import { useForm } from "react-hook-form";
import "./ForgotResetPassword.css";
import { forgotPassword } from "../../Services/Auth/Auth";
import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import Loader from "../../Components/Loader/Loader";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const ForgotPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [loading, setLoading] = useState(false);
  const onSubmit = async (data) => {
    // Check if email is entered
    if (!data.email) {
      setSnackbarMessage("Email is required");
      setSnackbarOpen(true);
      setSnackbarSeverity("error");
      return;
    }

    try {
      setLoading(true);
      // Call ForgotPassword and pass the email
      const res = await forgotPassword({
        email: data.email,
      });

      if (res.status === 200) {
        const message = res.data.message;
        setSnackbarMessage(message); // Set the snackbar message

        setSnackbarOpen(true);
        setSnackbarSeverity("success");
        setLoading(false);
      } else {
        // Handle error
        console.error("Failed to send email. Please try again.");
        setSnackbarMessage("Failed to send email. Please try again."); // Set the snackbar message
        setSnackbarOpen(true);
        setSnackbarSeverity("error");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error sending email:", error);
      setLoading(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div
          div
          className="sign-up-bg d-flex align-items-center justify-content-center"
        >
          <div className="sign-up-container d-flex align-items-center justify-content-center flex-column p-4">
            <div className="create-new-account-text pt-2">
              <p className="welcome-back text-center m-0 p-0 fs-4 fw-bold">
                Enter Your Email
              </p>
              <p className="text-center m-0 p-0 mb-3">
                Enter your registered email
              </p>
            </div>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="create-new-account-form"
            >
              {/* Email Field */}
              <div className="input-group d-flex flex-column mt-2">
                <div className="input-container">
                  <i
                    className="fa-regular fa-envelope fa-xl"
                    style={{ color: "#1877f2" }}
                  ></i>
                  <input
                    className={`form-control rounded-4 ${
                      errors.email ? "is-invalid" : ""
                    }`}
                    type="text"
                    placeholder="Enter Your Email Address"
                    {...register("email", {
                      required: "Email is required",
                      pattern: {
                        value:
                          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                        message: "Invalid email address",
                      },
                    })}
                  />
                </div>
                {errors.email ? (
                  <span className="error-message text-danger m-0 ms-1 mb-2">
                    {errors.email.message}
                  </span>
                ) : (
                  <div className="my-2 py-1"></div>
                )}
              </div>
              <div className="create-account-button w-100 pb-1">
                <button
                  type="submit"
                  className="submit-button btn btn-primary w-100 border-0 fw-bold fs-5 my-2 rounded-3 py-2"
                >
                  <p className="p-0 m-0">Submit</p>
                </button>
              </div>
            </form>
          </div>

          <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </div>
      )}{" "}
    </>
  );
};

export default ForgotPassword;
