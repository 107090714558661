import instance from "../instant";

const GetAllServices = async () => {
    try {
        const response = await instance.get('service');
        return response;
    } catch (error) {
        return error;
    }
};
const GetOneServices = async (id) => {
    try {
      
        const response = await instance.get(`service/by_id/${id}`);
        return response;
    } catch (error) {
        return error;
    }
};

const CreateServices = async (data) => {
    try {
        const headers = {}; 
        const token = JSON.parse(localStorage.getItem("token"));

        if (token !== null) {
            headers['Authorization'] = `Bearer ${token}`;
        }
        const response = await instance.post(`service/create`,data,{headers});
        return response;
    } catch (error) {
        return error;
    }
};
const GetMyServices = async () => {
    try {
        const headers = {}; 
        const token = JSON.parse(localStorage.getItem("token"));

        if (token !== null) {
            headers['Authorization'] = `Bearer ${token}`;
        }
        const response = await instance.get(`service/my`, { headers });
        return response;
    } catch (error) {
        return error;
    }
};


const CreateTutorReview = async (id,formData) => {
    try {
      const headers = {};
      const token = JSON.parse(localStorage.getItem("token"));

      if (token !== null) {
        headers["Authorization"] = `Bearer ${token}`;
      }
      const response = await instance.put(
        `service/add/review/${id}`,
        formData,
        { headers }
      );

      return response;
    } catch (error) {
      return error;
    }
};
const UpdateService = async (id,data) => {
    try {
      const headers = {}; 
      const token = JSON.parse(localStorage.getItem("token"));
  
      if (token !== null) {
        headers['Authorization'] = `Bearer ${token}`;
      }
  
      const response = await instance.patch(`service/update/${id}`, data,{
        headers,
      });
      
      return response;
    } catch (error) {
      console.error("Error fetching books:", error);
      return error;
    }
};
const DeleteService = async (id) => {
    try {
      const headers = {}; 
      const token = JSON.parse(localStorage.getItem("token"));
  
      if (token !== null) {
        headers['Authorization'] = `Bearer ${token}`;
      }
  
      const response = await instance.delete(`service/delete/${id}`,{
        headers,
      });
      
      return response;
    } catch (error) {
      console.error("Error fetching books:", error);
      return error;
    }
};
export { GetAllServices,GetOneServices,CreateServices,GetMyServices,CreateTutorReview,UpdateService,DeleteService};