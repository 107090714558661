export const calculateAverageRating = (obj) => {
  if (obj.reviews && obj.reviews.length > 0) {
    // Filter out empty review objects
    const validReviews = obj.reviews.filter(
      (review) => review && review.rating != null
    );

    if (validReviews.length > 0) {
      const totalRating = validReviews.reduce(
        (sum, review) => sum + review.rating,
        0
      );
      const averageRating = totalRating / validReviews.length;
      return parseFloat(averageRating.toFixed(1)); // Round to 1 decimal place
    }
  }
  return 0; // Return 0 if there are no valid reviews
};
