import React, { useState, useEffect } from "react";
import BookCard from "../../Components/BookCard/BookCard";
import DemandingBookButton from "../../Components/Buttons/DemandingBookButton/DemandingBookButton";
import Pagination from "../../Components/Pagination/Pagination";
import "./DemandingBooksLibrary.css";
import { GetAllBook } from "../../Services/Book/Book";
import { GetCategory } from "../../Services/Category/Category";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import Loader from "../../Components/Loader/Loader";
import { UseUser } from "../../Context/UserContext";

export default function DemandingBooksLibrary({
  ButtonComponent = DemandingBookButton,
}) {
  const [books, setBooks] = useState([]);
  const [backgroundColors, setBackgroundColors] = useState({});
  const [visibleBooks, setVisibleBooks] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [category, setCategory] = useState([]);
  const booksPerPage = 8;
  const [selectedGenre, setSelectedGenre] = useState("All");
  const [loading, setLoading] = useState(false); // Loading state
  const { user, setUser } = UseUser();

  const getRandomColor = async () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        const r = Math.floor(Math.random() * 256);
        const g = Math.floor(Math.random() * 256);
        const b = Math.floor(Math.random() * 256);
        resolve(`rgb(${r}, ${g}, ${b}, 0.35)`);
      }, 500);
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const fetchedBooks = await GetAllBook();
        const fetchCategory = await GetCategory();
        if (fetchedBooks.status === 200 && fetchCategory.status === 200) {
          const data = fetchedBooks.data.data;
          const categories = fetchCategory.data.data;
          const filteredBook = data.filter((book) => book.isOnDemand === true);
          setCategory(categories);
          let filteredBooks;
          if (selectedGenre === "All") {
            filteredBooks = filteredBook;
          } else {
            filteredBooks = filteredBook.filter((book) => {
              // Ensure the book has a category and return true/false for filtering
              return (
                book.category &&
                book.category.title.toLowerCase() ===
                  selectedGenre.toLowerCase()
              );
            });
          }

          const colorPromises = filteredBooks.map(async (book) => {
            const color = await getRandomColor();
            return { id: book._id, color };
          });

          const colors = await Promise.all(colorPromises);
          const colorMap = {};
          colors.forEach(({ id, color }) => {
            colorMap[id] = color;
          });

          setBooks(filteredBooks);
          setBackgroundColors(colorMap);
          setVisibleBooks(filteredBooks.slice(0, booksPerPage));
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [selectedGenre]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    const startIndex = (page - 1) * booksPerPage;
    const newBooks = books.slice(startIndex, startIndex + booksPerPage);
    setVisibleBooks(newBooks);
  };

  const totalPages = Math.ceil(books.length / booksPerPage);

  const handleGenreClick = (genre) => {
    setSelectedGenre(genre);
    setCurrentPage(1);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="selling-books-library">
          <div className="genre-selection-section pt-4 mb-2 fw-medium fs-md-5 fs-6">
            <Swiper
              spaceBetween={15}
              // navigation
              freeMode={true}
              watchSlidesProgress={true}
              modules={[FreeMode, Navigation, Thumbs]}
              className="mySwiper"
              grabCursor={true}
              slidesPerView={"auto"}
            >
              <SwiperSlide className="w-auto" key="All">
                <div
                  className={`genre-button py-2 px-4 ml-0 rounded-5 ${
                    selectedGenre === "All" ? "genre-button-active" : ""
                  }`}
                  onClick={() => handleGenreClick("All")}
                >
                  All
                </div>
              </SwiperSlide>

              {category.map((category) => (
                <SwiperSlide className="w-auto" key={category._id}>
                  <div
                    className={`genre-button py-2 px-4 ml-0 rounded-5 ${
                      selectedGenre === category.title
                        ? "genre-button-active"
                        : ""
                    }`}
                    onClick={() => handleGenreClick(category.title)}
                  >
                    {category.title}
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className="header mt-8">
            <h5 className=" fw-bold responsive-heading">Demanding Books</h5>
          </div>
          {visibleBooks.length > 0 ? (
            <div className="selling-book-library-cards-container w-100">
              {visibleBooks.map((book) => (
                <BookCard
                  key={book._id}
                  book={book}
                  backgroundColor={
                    backgroundColors[book._id] || "rgba(255, 255, 255, 0.8)"
                  }
                  ButtonComponent={ButtonComponent}
                  user={user}
                  setUser={setUser}
                />
              ))}{" "}
            </div>
          ) : (
            <div className="no-books-found-message mt-5 text-center">
              <p>No books available in this genre at the moment.</p>
            </div>
          )}
          {totalPages > 1 && (
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          )}
        </div>
      )}
    </>
  );
}
