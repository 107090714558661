import React from "react";
import Button from 'react-bootstrap/Button';
import "./CustomModal.css"; // Assuming you have custom styles for the modal

const CustomModal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="custom-backdrop" onClick={onClose}>
      <div className="custom-modal" onClick={(e) => e.stopPropagation()}>
        {children} {/* Render the passed children content */}
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </div>
    </div>
  );
};

export default CustomModal;
