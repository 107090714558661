  import { Route, Routes } from 'react-router-dom';
  import './App.css';
  import SignUpWith from './Pages/SignUpPages/SignUpWith';
  import CreateNewAccount from './Pages/SignUpPages/CreateNewAccount';
  import LoginPage from './Pages/LoginPage/LoginPage';
  import ForgotPassword from './Pages/ForgotResetPassword/ForgotPassword';
  import ResetPassword from './Pages/ForgotResetPassword/ResetPassword';
  import HomePage from './Pages/HomePage/HomePage';
  import NavBar from "./Components/NavBar/NavBar";
import SellBookForm from "./Pages/FormPages/SellBookForm/SellBookForm";
import SellTutorServices from "./Pages/FormPages/SellTutorServices/SellTutorServices";
import SellingBooksLibrary from "./Pages/SellingBooksLibrary/SellingBooksLibrary";
import DemandingBooksLibrary from "./Pages/DemandingBooksLibrary/DemandingBooksLibrary";
import BookDetails from "./Pages/BookDetails/BookDetails";
import TutorDetails from "./Pages/TutorDetails/TutorDetails";
import FavouriteAndSaved from "./Pages/FavouriteAndSaved/FavouriteAndSaved";
import TutorLibrary from "./Pages/TutorLibrary/TutorLibrary";
import EditProfile from "./Pages/EditProfile/EditProfile";
import MyBookAds from "./Pages/MyBookAds/MyBookAds";
import TutorServiceDraftPage from "./Pages/TutorServiceDraftPage/TutorServiceDraftPage";
import Footer from "./Components/Footer/Footer";
import PublicRoute from "./Layout/PublicRoute";
import ProtectedRoute from "./Layout/ProtectedRoute";
// import MyBookDemanding from "./Pages/MyBookDemanding/MyBookDemanding";
import UpdateBookForm from "./Pages/FormPages/UpdateBookForm/UpdateBookForm";
import UpdateServices from "./Pages/FormPages/UpdateService/UpdateService";
import UpdatePassword from "./Pages/UpdatePassword/UpdatePassword";
import AboutUs from "./Pages/AboutUs/AboutUs";
import PrivacyPolicy from "./Pages/PrivacyPolice/PrivacyPolicy";

// import ProtectedRoute from './Components/ProtectedRoute';
// import PublicRoute from './Components/PublicRoute'; // Import PublicRoute

function App() {
  return (
    <div className="App">
      <NavBar />
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route exact path="/about-us" element={<AboutUs />} />
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />

        <Route
          exact
          path="/selling-books-library"
          element={<SellingBooksLibrary />}
        />
        <Route
          exact
          path="/demanding-books-library"
          element={<DemandingBooksLibrary />}
        />
        <Route exact path="/book-details" element={<BookDetails />} />
        <Route exact path="/tutor-details" element={<TutorDetails />} />
        <Route exact path="/tutor-library" element={<TutorLibrary />} />
        {/* Public Routes */}
        <Route
          exact
          path="/sign-up-with"
          element={<PublicRoute element={<SignUpWith />} />}
        />
        <Route
          exact
          path="/create-new-account"
          element={<PublicRoute element={<CreateNewAccount />} />}
        />
        <Route
          exact
          path="/login"
          element={<PublicRoute element={<LoginPage />} />}
        />
        <Route
          exact
          path="/forgot-password"
          element={<PublicRoute element={<ForgotPassword />} />}
        />
        <Route
          exact
          path="/reset-password"
          element={<PublicRoute element={<ResetPassword />} />}
        />

        {/* Protected Routes */}
        <Route
          exact
          path="/sell-book-form/:id"
          element={<ProtectedRoute element={<UpdateBookForm />} />}
        />

        <Route
          exact
          path="/sell-tutor-services-form/:id"
          element={<ProtectedRoute element={<UpdateServices />} />}
        />
        <Route
          exact
          path="/update-password"
          element={<ProtectedRoute element={<UpdatePassword />} />}
        />
        <Route
          exact
          path="/sell-book-form"
          element={<ProtectedRoute element={<SellBookForm />} />}
        />
        <Route
          exact
          path="/demand-book-form"
          element={<ProtectedRoute element={<SellBookForm />} />}
        />
        <Route
          exact
          path="/sell-tutor-services-form"
          element={<ProtectedRoute element={<SellTutorServices />} />}
        />

        <Route
          exact
          path="/favourite-and-saved"
          element={<ProtectedRoute element={<FavouriteAndSaved />} />}
        />

        <Route
          exact
          path="/edit-profile"
          element={<ProtectedRoute element={<EditProfile />} />}
        />
        <Route
          exact
          path="/my-book-ads"
          element={<ProtectedRoute element={<MyBookAds />} />}
        />
        {/* 
        <Route
          exact
          path="/my-book-demand"
          element={<ProtectedRoute element={<MyBookDemanding />} />}
        /> */}
        <Route
          exact
          path="/tutor-service-draft-page"
          element={<ProtectedRoute element={<TutorServiceDraftPage />} />}
        />
      </Routes>
      <Footer />
    </div>
  );
}

  export default App;
