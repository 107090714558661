// src/Components/SellingBooksLibrary/SellingBooksLibrary.js
import React, { useState, useEffect, useRef } from "react";
import BookCard from "../../Components/BookCard/BookCard";
import SellingBookCardButtons from "../../Components/Buttons/SellingBookCardButtons/SellingBookCardButtons";
import Pagination from "../../Components/Pagination/Pagination";
import "./SellingBooksLibrary.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import { GetAllBook } from "../../Services/Book/Book";
import { GetCategory } from "../../Services/Category/Category";
import Loader from "../../Components/Loader/Loader";
import { UseUser } from "../../Context/UserContext";

export default function SellingBooksLibrary({
  ButtonComponent = SellingBookCardButtons,
}) {
  const [books, setBooks] = useState([]);
  const [backgroundColors, setBackgroundColors] = useState({});
  const [visibleBooks, setVisibleBooks] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [category, setCategory] = useState([]);
  const [selectedGenre, setSelectedGenre] = useState("All");
  const [loading, setLoading] = useState(false); // Loading state
  const { user, setUser, selectedCategory, setSelectedCategory } = UseUser();
  const swiperRef = useRef(null);

  const booksPerPage = 8;

  async function getRandomColor() {
    return new Promise((resolve) => {
      setTimeout(() => {
        const r = Math.floor(Math.random() * 256);
        const g = Math.floor(Math.random() * 256);
        const b = Math.floor(Math.random() * 256);
        resolve(`rgb(${r}, ${g}, ${b}, 0.35)`);
      }, 500);
    });
  }
  useEffect(() => {
    if (selectedCategory) {
      setSelectedGenre(selectedCategory);
    }
  }, [selectedCategory]);

  // Fetch data and generate background colors
  useEffect(() => {
    async function fetchData() {
      setLoading(true); // Set loading to true when fetching data
      try {
        const fetchedBooks = await GetAllBook();
        const fetchCategory = await GetCategory();

        if (fetchedBooks.status === 200 && fetchCategory.status === 200) {
          const data = fetchedBooks.data.data;
          const categories = fetchCategory.data.data;
          const filteredBook = data.filter((book) => book.isOnDemand === false);
          setCategory(categories);

          let filteredBooks;

          if (selectedCategory) {
            filteredBooks = filteredBook.filter((book) => {
              if (book.category) {
                return (
                  book.category.title.toLowerCase() ===
                  selectedCategory.toLowerCase()
                );
              }
              return false;
            });
          } else {
            if (selectedGenre === "All") {
              filteredBooks = filteredBook;
            } else {
              filteredBooks = filteredBook.filter((book) => {
                if (book.category) {
                  return (
                    book.category.title.toLowerCase() ===
                    selectedGenre.toLowerCase()
                  );
                }
                return false;
              });
            }
          }
          const colorPromises = filteredBooks.map(async (book) => {
            const color = await getRandomColor();
            return { id: book._id, color };
          });

          const colors = await Promise.all(colorPromises);
          const colorMap = {};
          colors.forEach(({ id, color }) => {
            colorMap[id] = color;
          });

          setBooks(filteredBooks);
          setBackgroundColors(colorMap);
          setVisibleBooks(filteredBooks.slice(0, booksPerPage));
        }
      } catch (error) {
        console.error("Error fetching books or categories:", error);
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, [selectedCategory, selectedGenre]);

  // Handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
    const startIndex = (page - 1) * booksPerPage;
    const newBooks = books.slice(startIndex, startIndex + booksPerPage);
    setVisibleBooks(newBooks);
  };

  // Handle genre button click
  const handleGenreClick = (genre) => {
    setSelectedGenre(genre);
    const updatedBooks = visibleBooks.filter((book) => {
      if (book.category) {
        return book.category.title.toLowerCase() === genre.toLowerCase();
      }
      return false;
    });
    setSelectedCategory(null);
    setVisibleBooks(updatedBooks);

    setCurrentPage(1);
  };
  useEffect(() => {
    if (swiperRef.current && selectedGenre !== "All") {
      const index = category.findIndex(
        (cat) => cat.title.toLowerCase() === selectedGenre.toLowerCase()
      );
      if (index > -1) {
        swiperRef.current.slideTo(index);
      }
    }
  }, [selectedGenre, category]);
  const totalPages = Math.ceil(books.length / booksPerPage);

  return (
    <>
      {" "}
      {loading ? ( // Show loading indicator
        <Loader />
      ) : (
        <div className="selling-books-library">
          <div className="genre-selection-section pt-4 mb-2 fw-medium fs-md-5 fs-6">
            <Swiper
              onSwiper={(swiper) => (swiperRef.current = swiper)}
              spaceBetween={15}
              freeMode={true}
              watchSlidesProgress={true}
              modules={[FreeMode, Navigation, Thumbs]}
              className="mySwiper"
              grabCursor={true}
              slidesPerView={"auto"}
            >
              <SwiperSlide className="w-auto" key="All">
                <div
                  className={`genre-button py-2 px-4 ml-0 rounded-5 ${
                    selectedGenre === "All" ? "genre-button-active" : ""
                  }`}
                  onClick={() => handleGenreClick("All")}
                >
                  All
                </div>
              </SwiperSlide>

              {category.map((category) => (
                <SwiperSlide className="w-auto" key={category._id}>
                  <div
                    className={`genre-button py-2 px-4 ml-0 rounded-5 ${
                      selectedGenre === category.title
                        ? "genre-button-active"
                        : ""
                    }`}
                    onClick={() => handleGenreClick(category.title)}
                  >
                    {category.title}
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className="header mt-8">
            <h5 className=" fw-bold responsive-heading">Selling Books</h5>
          </div>
          {visibleBooks.length > 0 ? (
            <div className="selling-book-library-cards-container w-100">
              {visibleBooks.map((book) => (
                <BookCard
                  key={book.id}
                  book={book}
                  backgroundColor={
                    backgroundColors[book.id] || "rgba(255, 255, 255, 0.8)"
                  }
                  ButtonComponent={ButtonComponent}
                  user={user}
                  setUser={setUser}
                />
              ))}
            </div>
          ) : (
            <div className="no-books-found-message mt-5 text-center">
              <p>No books available in this category at the moment.</p>
            </div>
          )}

          {totalPages > 1 && (
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          )}
        </div>
      )}
    </>
  );
}
