import React, { useEffect, useState } from "react";
import "../SellTutorServices/SellTutorServices.css";
import { Dropdown } from "primereact/dropdown";
import Button from "react-bootstrap/Button";
import {
  GetOneServices,
  UpdateService,
} from "../../../Services/tutorServices/Services";
import PositionedSnackbar from "../../../Components/Snackbar/Snackbar";
import { useNavigate, useParams } from "react-router-dom";

const UpdateServices = () => {
  const { id } = useParams();
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [selectedTeachingMethod, setSelectedTeachingMethod] = useState(null);
  const [initialServiceData, setInitialServiceData] = useState({});
  const [loading, setLoading] = useState(false);

  // New state variables for each input field
  const [subject, setSubject] = useState("");
  const [qualification, setQualification] = useState("");
  const [experience, setExperience] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState("");

  const formatTime = (timeString) => {
    const date = new Date(timeString);
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${hours}:${minutes}`;
  };
  const extractExperience = (experienceString) => {
    const match = experienceString.match(/\d+/); // Match one or more digits
    return match ? parseInt(match[0], 10) : 0; // Convert to integer or return 0 if no match
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const languages = [
    { code: "ur", name: "Urdu" },
    { code: "en", name: "English" },
    { code: "ar", name: "Arabic" },
    { code: "pa", name: "Punjabi" },
    { code: "sd", name: "Sindhi" },
    { code: "ps", name: "Pashto" },
    { code: "gk", name: "Gilgiti" },
  ];
  useEffect(() => {
    if (id) {
      GetOneServices(id)
        .then((data) => {
          if (data.status === 200) {
            const res = data.data.data;
            const selectedLanguage = languages.find(
              (lang) => lang.name.toLowerCase() === res.language.toLowerCase()
            );
            setPhoneNumber(res.phoneNumber);
            if (selectedLanguage) {
              setSelectedLanguage({
                name: selectedLanguage.name,
                code: selectedLanguage.code,
              });
            }
            setSelectedTeachingMethod({
              name: res.teach_method,
              code: res.teach_method.toLowerCase(),
            });
            setSubject(res.subject || "");
            setQualification(res.qualification);
            setExperience(extractExperience(res.experience));
            setDescription(res.description);
            setPrice(res.fees);
            setStartTime(formatTime(res.availability.from));
            setEndTime(formatTime(res.availability.to));

            setInitialServiceData({
              availability: {
                from: formatTime(res.availability.from),
                to: formatTime(res.availability.to),
              },
              description: res.description,
              experience: extractExperience(res.experience),
              fees: res.fees,
              language: res.language,
              qualification: res.qualification,
              subject: res.subject,
              teach_method: res.teach_method,
              phoneNumber: res.phoneNumber,
            });
          }
        })
        .catch((error) => console.error("Error fetching tutor data:", error));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleUpdate = async () => {
    // Create an object to hold the changes
    const updates = {};
    setLoading(true);
    // Compare current state with initialServiceData
    if (subject !== initialServiceData.subject) updates.subject = subject;
    if (price !== initialServiceData.fees) updates.fees = price;
    if (selectedLanguage?.name !== initialServiceData.language)
      updates.language = selectedLanguage.name;
    if (selectedTeachingMethod?.name !== initialServiceData.teach_method)
      updates.teach_method = selectedTeachingMethod.name;
    if (qualification !== initialServiceData.qualification)
      updates.qualification = qualification;
    if (experience !== initialServiceData.experience)
      updates.experience = `${experience} years`;
    if (description !== initialServiceData.description)
      updates.description = description;
    const today = new Date().toISOString().split("T")[0];
    if (phoneNumber !== initialServiceData.phoneNumber)
      updates.phoneNumber = phoneNumber;
    // Only update availability if times have changed
    if (
      startTime !== initialServiceData.availability.from ||
      endTime !== initialServiceData.availability.to
    ) {
      const formattedStartTime = new Date(
        `${today}T${startTime}:00Z`
      ).toISOString();
      const formattedEndTime = new Date(
        `${today}T${endTime}:00Z`
      ).toISOString();

      updates.availability = JSON.stringify({
        from: formattedStartTime,
        to: formattedEndTime,
      });
    }

    // If no fields have changed, show a message and exit
    if (Object.keys(updates).length === 0) {
      setSnackbarMessage("No fields were changed!");
      setSnackbarSeverity("info");
      setSnackbarOpen(true);
      return;
    }

    try {
      const res = await UpdateService(id, updates);
      if (res.status === 200) {
        setSnackbarMessage("Service updated successfully!");
        setSnackbarOpen(true);

        setTimeout(() => {
          navigate("/tutor-service-draft-page");
          setLoading(false);
        }, 1000);
      } else if (res.status === 401) {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        navigate("/login");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error updating service:", error);
      setSnackbarMessage("Failed to update service.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      setLoading(false);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  // Convert to 24-hour format and then to minutes
  const convertTo24HourAndMinutes = (time) => {
    let [hours, minutes] = time.split(":");
    hours = parseInt(hours, 10);

    // Adjust for AM/PM
    const isPM = time.toLowerCase().includes("pm");
    const isAM = time.toLowerCase().includes("am");

    if (isPM && hours !== 12) {
      hours += 12;
    } else if (isAM && hours === 12) {
      hours = 0; // Handle midnight case (12 AM)
    }

    return hours * 60 + parseInt(minutes, 10); // Return total minutes from midnight
  };

  // Handle end time change and validation
  const handleEndTimeChange = (e) => {
    const selectedEndTime = e.target.value;

    if (startTime && selectedEndTime) {
      const startMinutes = convertTo24HourAndMinutes(startTime);
      const endMinutes = convertTo24HourAndMinutes(selectedEndTime);

      // Ensure the end time is greater than the start time
      if (endMinutes <= startMinutes) {
        setSnackbarMessage("End time must be greater than start time.");
        setSnackbarOpen(true);
      } else {
        setEndTime(selectedEndTime);
      }
    }
  };
  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    // Allow only digits and limit to 11 characters
    if (/^\d{0,11}$/.test(value)) {
      setPhoneNumber(value);
    }
  };
  return (
    <div>
      <PositionedSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        handleClose={handleSnackbarClose}
      />
      <div className="sell-tutor-services-form">
        <div className="header">
          <h5 className="fs-4 fw-bold">Tutor Service</h5>
        </div>

        <div className="field-container d-flex align-items-start align-items-md-center justify-content-between flex-column flex-md-row column-gap-2 rounded-4 p-3 my-3">
          <label className="fs-5 p-0 m-0">Subject:</label>
          <input
            type="text"
            className="w-100 p-0 m-0 fs-5"
            placeholder="Enter subject"
            value={subject} // Bind the state variable
            onChange={(e) => setSubject(e.target.value)} // Update state on change
          />
        </div>

        <div className="d-flex align-items-center justify-content-between column-gap-2 row-gap-3 my-3 flex-column flex-md-row">
          <div className="field-container d-flex align-items-start align-items-md-center justify-content-between flex-column flex-md-row column-gap-2 rounded-4 p-3 w-100">
            <label className="fs-5 p-0 m-0">Qualification:</label>
            <input
              type="text"
              className="w-100 p-0 m-0 fs-5"
              placeholder="Enter your qualification"
              value={qualification} // Bind the state variable
              onChange={(e) => setQualification(e.target.value)} // Update state on change
            />
          </div>
          <div className="field-container d-flex align-items-start align-items-md-center justify-content-between flex-column flex-md-row column-gap-2 rounded-4 p-3 w-100">
            <label className="fs-5 p-0 m-0">Experience:</label>
            <input
              type="number" // Change the input type to "number"
              className="w-100 p-0 m-0 fs-5"
              placeholder="Enter your experience in years"
              value={experience} // Bind the state variable
              onChange={(e) => setExperience(e.target.value)} // Update state on change
              min="0" // Optional: Prevent negative values
            />
          </div>
        </div>
        <div className="field-container d-flex align-items-start align-items-md-center justify-content-between flex-column flex-md-row column-gap-2 rounded-4 p-3 my-3">
          <label className="fs-5 p-0 m-0">Mode:</label>
          <div className="w-100">
            <Dropdown
              value={selectedTeachingMethod}
              onChange={(e) => setSelectedTeachingMethod(e.value)}
              options={[
                { name: "Onsite", code: "onsite" },
                { name: "Online", code: "online" },
                { name: "Both", code: "both" },
                { name: "Other", code: "other" },
              ]}
              optionLabel="name"
              showClear
              placeholder="Select Teaching Method"
              className="custom-dropdown d-flex align-items-center justify-content-between fs-5"
            />
          </div>
        </div>

        <div className="field-container d-flex align-items-start align-items-md-center justify-content-between flex-column flex-md-row column-gap-2 rounded-4 p-3 w-100">
          <label className="fs-5 p-0 m-0">Language:</label>
          <div className="w-100">
            <Dropdown
              value={selectedLanguage}
              onChange={(e) => setSelectedLanguage(e.value)}
              options={languages}
              optionLabel="name"
              showClear
              placeholder="Select Language"
              className="custom-dropdown d-flex align-items-center justify-content-between fs-5"
            />
          </div>
        </div>

        <div className="field-container d-flex align-items-start align-items-md-center justify-content-between flex-column flex-md-row column-gap-2 rounded-4 p-3 my-3">
          <label className="align-self-start fs-5 p-0 m-0">Description:</label>
          <textarea
            rows={5}
            className="w-100 p-0 m-0 fs-5"
            placeholder="Enter description"
            value={description} // Bind the state variable
            onChange={(e) => setDescription(e.target.value)} // Update state on change
          />
        </div>

        <div className="field-container d-flex align-items-start align-items-md-center justify-content-between flex-column flex-md-row column-gap-2 rounded-4 p-3 my-3">
          <label className="fs-5 p-0 m-0">Availability:</label>
          <div className="w-100 d-flex flex-column flex-md-row gap-2">
            <div className="field-container d-flex align-items-baseline justify-content-between flex-column flex-md-row column-gap-4 rounded-4 p-3 my-3">
              <label className="fs-6">From</label>
              <input
                type="time"
                value={startTime}
                onChange={(e) => setStartTime(e.target.value)} // Use state to manage time
                className="fs-5"
              />
            </div>
            <div className="field-container d-flex align-items-baseline justify-content-between flex-column flex-md-row column-gap-4 rounded-4 p-3 my-3">
              <label className="fs-6">To</label>
              <input
                type="time"
                value={endTime}
                onChange={handleEndTimeChange}
                className="fs-5"
              />
            </div>
          </div>
        </div>
        <div className="field-container d-flex align-items-start align-items-md-center justify-content-between flex-column flex-md-row column-gap-2 rounded-4 p-3 my-3">
          <label htmlFor="phoneNumber" className="fs-5 p-0 m-0 w-max">
            Phone No:
          </label>
          <input
            type="text"
            id="phoneNumber"
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            placeholder="Enter phone number"
            className="flex-grow p-0 m-0 fs-5"
          />
        </div>
        <div className="field-container gap-2 rounded-4 p-3 my-3">
          <label className="fs-5 p-0 m-0">Price:</label>
          <input
            type="number"
            className="w-100 p-0 m-0 fs-5"
            placeholder="Enter your price"
            value={price} // Bind the state variable
            onChange={(e) => setPrice(e.target.value)} // Update state on change
          />
        </div>

        <div className="d-flex align-items-center justify-content-center flex-column flex-md-row column-gap-5 row-gap-2 my-4">
          <Button
            className="bg-primary text-white"
            onClick={handleUpdate}
            disabled={loading}
          >
            {loading ? "Loading" : "Update Service"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UpdateServices;
