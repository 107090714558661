import React, { useEffect, useState } from "react";
import "./EditProfile.css";
import Button from "react-bootstrap/Button";
import { UseUser } from "../../Context/UserContext";
import { fetchCityName } from "../../libs/formateLocation";
import { DeleteAccount, UpdateProfile } from "../../Services/Auth/Auth";
import MuiAlert from "@mui/material/Alert";
import { Snackbar } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const EditProfile = () => {
  const { user, setUser } = UseUser();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [image, setImage] = useState(null);
  const [city, setCity] = useState(null);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // Default to success
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      setName(user.name);
      setEmail(user.email);
      setImage(user.profileImage);
      if (user.location && user.location.coordinates) {
        fetchCityName(user.location.coordinates, setCity);
      }
    }
  }, [user]);
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleDeleteAccount = async () => {
    const response = await DeleteAccount();
    if (response.status === 200) {
      setSnackbarMessage("Account Delete successfully!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);

      localStorage.removeItem("token");
      localStorage.removeItem("sessionStartTime");
      localStorage.removeItem("user");
      setTimeout(() => {
        navigate("/");
      }, 500);
    } else if (response.status === 401) {
      setLoading(false);
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      navigate("/login");
    } else {
      console.error("Failed to delete account:", response);
      setSnackbarMessage("Failed to delete account!");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };
  const handleSaveChanges = async () => {
    const updatedFields = {};

    if (name !== user.name) {
      updatedFields.name = name;
    }
    if (email !== user.email) {
      updatedFields.email = email;
    }

    if (image && image !== user.profileImage) {
      updatedFields.profileImage = image;
    }

    if (Object.keys(updatedFields).length === 0) {
      return;
    }

    setLoading(true);
    try {
      const res = await UpdateProfile(updatedFields);

      if (res.status === 200) {
        setUser(res.data.data);
        localStorage.setItem("user", JSON.stringify(res.data.data));
        setSnackbarMessage("Profile updated successfully!");
        setSnackbarOpen(true);
        setSnackbarSeverity("success"); // Set severity to success
      } else if (res.status === 401) {
        setLoading(false);
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        navigate("/login");
      } else if (res.status === 404) {
        setSnackbarMessage(res.message);
        setName(user.name);
        setEmail(user.email);
        setSnackbarOpen(true);

        setSnackbarSeverity("error"); // Set severity to error
      } else {
        setSnackbarOpen(true);
        setSnackbarMessage("Failed to update profile. Please try again.");
        setSnackbarSeverity("error"); // Set severity to error
      }
    } catch (error) {
      setSnackbarOpen(true);

      console.error("Error updating profile:", error);
      setSnackbarMessage("Error updating profile. Please try again.");
      setSnackbarSeverity("error"); // Set severity to error
    } finally {
      setLoading(false);
      setSnackbarOpen(true);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <div className="edit-profile-form">
      <div className="header my-6">
        <h5 className="fs-4 fw-bold">Edit Profile</h5>
      </div>
      <div className="d-flex align-items-center justify-content-start gap-5 rounded-5 flex-column flex-md-row">
        <div className="profile-image-container">
          {image ? (
            <img src={image} alt="Profile" className="profile-image" />
          ) : (
            <div className="profile-image-before d-flex align-items-center justify-content-center">
              <i
                className="fa-solid fa-user"
                style={{ color: "white", fontSize: "80px" }}
              ></i>
            </div>
          )}
          <label
            htmlFor="profile-image-upload"
            className="add-profile-image-button"
          >
            <i
              className="fa-solid fa-camera p-1"
              style={{ color: "#ffffff" }}
            ></i>
            <input
              id="profile-image-upload"
              type="file"
              style={{ display: "none" }}
              accept="image/*"
              onChange={handleImageUpload}
              disabled={loading}
            />
          </label>
        </div>
        <div className="w-100">
          <div className="field-container d-flex align-items-start align-items-md-center justify-content-between flex-column flex-md-row gap-1 rounded-4 p-3 mb-3">
            <label className="fs-5 p-0 m-0">Name:</label>
            <input
              type="text"
              className="p-0 m-0 fs-5"
              placeholder="Enter your name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              disabled={loading} // Disable input if loading
            />
          </div>
          <div className="field-container d-flex align-items-start align-items-md-center justify-content-between gap-1 rounded-4 p-3 w-100 flex-column flex-md-row">
            <label className="fs-5 p-0 m-0">Email:</label>
            <input
              type="text"
              className="flex-grow-1 p-0 m-0 fs-5"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={loading} // Disable input if loading
            />
          </div>
          <div className="field-container d-flex align-items-start align-items-md-center justify-content-between flex-column flex-md-row gap-1 rounded-4 p-3 my-3">
            <label className="fs-5 p-0 m-0">Address:</label>
            <input
              type="text"
              className="p-0 m-0 fs-5"
              placeholder="Address"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              disabled // Address input remains disabled
            />
          </div>
        </div>
      </div>

      <div className="d-flex align-items-center justify-content-end gap-3 my-4 flex-column flex-md-row">
        <Button
          variant="danger"
          className="delete-account-button fs-5 rounded-2 py-3 px-5 "
          onClick={handleDeleteAccount}
          disabled={loading} // Disable button if loading
        >
          {loading ? "Deleting..." : "Delete Account"}
        </Button>
        <Button
          variant="primary"
          className="sale-now-button fs-5 rounded-2 py-3 px-5 "
          onClick={handleSaveChanges}
          disabled={loading} // Disable button if loading
        >
          {loading ? "Saving..." : "Save Changes"}{" "}
        </Button>
      </div>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default EditProfile;
