import instance from "../instant";
const GetHome = async () => {
    try {
        const response = await instance.get('home');
        return response;
    } catch (error) {
        return error;
    }
};


export { GetHome};