import React from "react";
import "./AboutUs.css";
const AboutUs = () => {
  return (
    <div className="about-us-container">
      <img src="./green.png" alt="" className="green" />

      {/* Header Section */}
      <div className="about-section">
        <img src="./red.png" alt="" className="red" />

        {/* Hero Content */}
        <div className="about-content d-flex align-items-center justify-content-between">
          <img src="./blue.png" alt="" className="blue" />
          <div className="about-text">
            <h2>Best Place to Sell & Find Your </h2>
            <h2>Favorite Books</h2>
            <p className="">
              Welcome to Books Brary, your haven for literary treasures! We are
              passionate about bringing readers closer to the stories they love,
              whether they're classic tales, modern masterpieces, or hidden gems
              waiting to be discovered.
            </p>
          </div>
          <div className="about-img">
            <img src="./about.png" alt="" />
          </div>
        </div>
      </div>

      {/* Statistics Section */}
      <section className="statistics-section ">
        <div className="stat-item">
          <h2>450</h2>
          <p style={{ color: "#007bff" }}>All Tutor</p>
        </div>
        <div className="stat-item">
          <h2>10k</h2>
          <p style={{ color: "#F62B2B" }}>Exchange Books</p>
        </div>
        <div className="stat-item">
          <h2>5K</h2>
          <p style={{ color: "#35ED82" }}>Total Books</p>
        </div>
        <div className="stat-item">
          <h2>100K</h2>
          <p style={{ color: "#F5972C" }}>Active User</p>
        </div>
      </section>

      {/* Mission and Story Section */}
      <section className="mission-story-section">
        <div className="mission-section">
          <h3>Our Mission</h3>
          <p>
            At Books Brary, our mission is to create a community where readers
            can explore a diverse collection of books across all genres. We
            believe in the magic of books and their power to inspire, educate,
            and entertain. Our carefully curated selection ensures that every
            reader finds something that resonates with them.
          </p>
        </div>
        <div className="story-section">
          <h3>Our Story</h3>
          <p>
            Founded by a group of avid readers, Books Brary was born out of a
            desire to share the joy of reading with others. We started as a
            small, independent bookstore and have grown into a beloved
            destination for book lovers both online and offline. Our name,
            "Briary," reflects our vision of a cozy, inviting space where
            stories grow and flourish.
          </p>
        </div>
      </section>

      {/* Why Choose Us Section */}
      <section className="why-choose-section">
        <h3>Why Choose Books Brary?</h3>
        <p>
          We are more than just a bookstore; we are a community of readers who
          share a deep love for books. Whether you’re looking for the latest
          release or a timeless classic, our knowledgeable staff is here to
          guide you. We pride ourselves on our friendly service attention to
          detail, and our dedication to making your reading experience
          unforgettable. Join the Books Brary family today and embark on a
          literary adventure like no other!
        </p>
      </section>
    </div>
  );
};

export default AboutUs;
