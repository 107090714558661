// src/Components/TutorLibrary/TutorLibrary.js
import React, { useState, useEffect } from "react";
import TutorCard from "../../Components/TutorCard/TutorCard";
import Pagination from "../../Components/Pagination/Pagination"; // Replace GradientButton with Pagination
import "./TutorLibrary.css";
import { GetAllServices } from "../../Services/tutorServices/Services";
import Loader from "../../Components/Loader/Loader";

export default function TutorLibrary() {
  const [tutors, setTutors] = useState([]);
  const [visibleTutors, setVisibleTutors] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const tutorsPerPage = 10;

  useEffect(() => {
    async function fetchData() {
      const fetchedTutors = await GetAllServices();
      if (fetchedTutors.status === 200) {
        const TutorsData = fetchedTutors.data.data;
        setTutors(TutorsData);
        setVisibleTutors(TutorsData.slice(0, tutorsPerPage));
      }
    }
    fetchData();
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    const startIndex = (page - 1) * tutorsPerPage;
    const newTutors = tutors.slice(startIndex, startIndex + tutorsPerPage);
    setVisibleTutors(newTutors);
  };

  const totalPages = Math.ceil(tutors.length / tutorsPerPage);
  if (visibleTutors.length === 0 && tutors.length === 0) {
    return <Loader />;
  }
  return (
    <div className="tutor-library">
      <div className="tutor-library-cards-container">
        {visibleTutors.map((tutor) => (
          <TutorCard key={tutor.id} tutor={tutor} />
        ))}
      </div>
      {totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      )}
    </div>
  );
}
