import React, { useState } from "react";
import "./TutorServiceDraftCard.css";
import Rating from "@mui/material/Rating";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../libs/FormateTime";
import { calculateAverageRating } from "../../libs/calculateAvgerageRating";
import { fetchCityName } from "../../libs/formateLocation";
import { DeleteService } from "../../Services/tutorServices/Services";

const TutorServiceDraftCard = ({
  tutor,
  setTutors,
  setVisibleTutors,
  setSnackbarOpen,
  setSnackbarSeverity,
  setSnackbarMessage,
}) => {
  const navigate = useNavigate();
  const tutorId = tutor._id;
  const [city, setCity] = useState("");
  const handleEdit = () => {
    navigate(`/sell-tutor-services-form/${tutorId}`);
  };
  const handleRemove = async () => {
    try {
      const response = await DeleteService(tutorId);

      if (response.status === 200) {
        setSnackbarOpen(true);
        setSnackbarMessage("The book has been deleted successfully!");
        setSnackbarSeverity("success");

        // Update the book state locally if the API call succeeds
        setTutors((prevtutor) => prevtutor.filter((t) => t._id !== tutorId));
        setVisibleTutors((prevtutor) =>
          prevtutor.filter((t) => t._id !== tutorId)
        );
      } else {
        throw new Error("Failed to delete the book.");
      }
    } catch (error) {
      console.error("Error deleting the book:", error);
      setSnackbarOpen(true);
      setSnackbarMessage("Error deleting the book. Please try again.");
      setSnackbarSeverity("error");
    }
  };
  const fromTime = formatDate(tutor.availability.from);
  const toTime = formatDate(tutor.availability.to);

  const averageRating = calculateAverageRating(tutor);
  fetchCityName(tutor.user_id.location.coordinates, setCity);

  return (
    <>
      <div className="tutor-service-draft-card-main-container rounded-5 p-4">
        <div className="tutor-service-draft-card-container d-flex align-items-center justify-content-evenly flex-column flex-lg-row row-gap-4">
          <div className="tutor-service-draft-card-image d-flex align-items-center justify-content-center rounded-4">
            {tutor.user_id.profileImage !== "" ? (
              <img
                src={tutor.user_id.profileImage}
                alt={tutor.user_id.name}
                className="rounded-4"
              />
            ) : (
              <div
                className="tutor-image-before  d-flex align-items-center justify-content-center"
                // style={{ width: "15rem" }}
              >
                <i
                  className="fa-solid fa-user"
                  style={{ color: "white", fontSize: "80px" }}
                ></i>
              </div>
            )}
          </div>
          <div className="tutor-service-draft-card-text text-center text-lg-start w-75">
            <h3 className="tutor-service-draft-card-title m-0 fw-bold fs-2 mb-2">
              {tutor.user_id.name}
            </h3>
            <div className="location-row d-flex align-items-center justify-content-between my-2 flex-column flex-lg-row row-gap-2">
              <p className="tutor-service-draft-card-location m-0 fs-6 fs-md-5">
                <i
                  className="fa-solid fa-location-dot me-2"
                  style={{ color: "#000000" }}
                />
                {city}
              </p>
              <p className="tutor-service-draft-card-time m-0 align-self-md-end">
                {fromTime} - {toTime} Mon-Fri
              </p>
            </div>
            <div className="location-row d-flex align-items-center justify-content-between my-2 flex-column flex-lg-row row-gap-4">
              <p className="tutor-service-draft-card-price m-0 py-1 px-3 fs-6 fs-md-5 rounded-5">
                Rs.{tutor.fees}/hour {/* Dummy price text */}
              </p>
              <div className="d-flex align-items-center">
                <Rating
                  size="small"
                  name="half-rating-read"
                  value={averageRating}
                  precision={0.5}
                  readOnly
                />
                <p
                  className="m-0 ms-1"
                  style={{ fontSize: "12px", color: "#faaf00" }}
                >
                  {averageRating}/5
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="tutor-service-draft-card-description-section fw-medium my-5  text-lg-start">
          <h4 className="mb-2">Description</h4>
          <p className="m-0 fw-light">
            {tutor.description} Lorem ipsum dolor sit, amet consectetur
            adipisicing elit. Quisquam reiciendis ullam molestias corrupti ab
            quibusdam nobis vitae blanditiis obcaecati odio.
          </p>
        </div>

        <div className="ad-card-extra-details-section fw-medium my-5">
          <h4 className="m-0 text-center text-lg-start">Details</h4>
          <div className="extra-details d-flex justify-content-around mt-3">
            <div className="extra-details-item text-center fs-6">
              <div className="extra-detail-heading">
                Subject
                <span className="colon">:</span>
              </div>
              <div className="extra-detail-value">{tutor.subject}</div>
            </div>
            <div className="extra-details-item text-center fs-6">
              <div className="extra-detail-heading">
                Language
                <span className="colon">:</span>
              </div>
              <div className="extra-detail-value">{tutor.language}</div>
            </div>
            <div className="extra-details-item text-center fs-6">
              <div className="extra-detail-heading">
                Experience
                <span className="colon">:</span>
              </div>
              <div className="extra-detail-value">{tutor.experience}</div>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-center w-100 w-md-75 m-auto gap-3  row-gap-2">
          <Button
            variant="primary"
            className="tutor-service-draft-card-remove-button fs-5 rounded-3 w-50"
            onClick={handleRemove}
          >
            Remove
          </Button>
          <Button
            variant="primary"
            className="tutor-service-draft-card-publish-button fs-5 rounded-3 w-50"
            onClick={handleEdit}
          >
            Edit
          </Button>
        </div>
      </div>
    </>
  );
};

export default TutorServiceDraftCard;
