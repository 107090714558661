import React, { useState, useEffect } from "react";
import BookCard from "../../Components/BookCard/BookCard";
import SellingBookCardButtons from "../../Components/Buttons/SellingBookCardButtons/SellingBookCardButtons";
import Pagination from "../../Components/Pagination/Pagination";
import "./FavouriteAndSaved.css";
import Loader from "../../Components/Loader/Loader";
import { GetUser } from "../../Services/Auth/Auth";
import { useNavigate } from "react-router-dom";
import { UseUser } from "../../Context/UserContext";

export default function FavouriteAndSaved({
  ButtonComponent = SellingBookCardButtons,
}) {
  const [books, setBooks] = useState([]);
  const [backgroundColors, setBackgroundColors] = useState({});
  const [visibleBooks, setVisibleBooks] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { user, setUser } = UseUser();

  const booksPerPage = 8;
  async function getRandomColor() {
    return new Promise((resolve) => {
      setTimeout(() => {
        const r = Math.floor(Math.random() * 256);
        const g = Math.floor(Math.random() * 256);
        const b = Math.floor(Math.random() * 256);
        resolve(`rgb(${r}, ${g}, ${b}, 0.35)`);
      }, 500);
    });
  }

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const token = JSON.parse(localStorage.getItem("token"));
      const response = await GetUser(token);
      if (response.status === 200) {
        if (response.data.data.favouriteBooks.length > 0) {
          const colorPromises = response.data.data.favouriteBooks.map(
            async (book) => {
              const color = await getRandomColor();
              return { id: book._id, color };
            }
          );
          const colors = await Promise.all(colorPromises);
          const colorMap = {};
          colors.forEach(({ id, color }) => {
            colorMap[id] = color;
          });
          setBooks(response.data.data.favouriteBooks);
          setBackgroundColors(colorMap);
          setVisibleBooks(
            response.data.data.favouriteBooks.slice(0, booksPerPage)
          );
          setLoading(false);
        } else {
          setLoading(false);
          setVisibleBooks([]);
          setBooks([]);
        }
      } else if (response.status === 401) {
        setLoading(false);
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        navigate("/login");
      }
    }
    fetchData();
  }, [navigate]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    const startIndex = (page - 1) * booksPerPage;
    const newBooks = books.slice(startIndex, startIndex + booksPerPage);
    setVisibleBooks(newBooks);
  };
  const onUnfavorite = (bookId) => {
    setBooks((prevBooks) => prevBooks.filter((book) => book._id !== bookId));
    setVisibleBooks((prevVisibleBooks) =>
      prevVisibleBooks.filter((book) => book._id !== bookId)
    );
  };

  const totalPages = Math.ceil(books.length / booksPerPage);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          {visibleBooks.length === 0 ? (
            <div className="screen-size">No Favorite Book found</div>
          ) : (
            <div className="favorite-books-library">
              <div className="favorite-books-cards-container">
                {visibleBooks.map((book) => (
                  <BookCard
                    key={book.id}
                    book={book}
                    backgroundColor={
                      backgroundColors[book.id] || "rgba(255, 255, 255, 0.8)"
                    }
                    ButtonComponent={ButtonComponent}
                    user={user}
                    setUser={setUser}
                    onUnfavorite={onUnfavorite}
                  />
                ))}
                {totalPages > 1 && (
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                  />
                )}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}
