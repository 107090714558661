import React, { useState, useEffect } from "react";
import BookCarousal from "../../Components/BookCarousal/BookCarousal";
import GradientButton from "../../Components/Buttons/GradientButton/GradientButton";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import TypesCarousel from "../../Components/TypesCarousel/TypesCarousel";
import TutorCarousel from "../../Components/TutorCarousel/TutorCarousel";
import SellingBookCardButtons from "../../Components/Buttons/SellingBookCardButtons/SellingBookCardButtons";
import DemandingBookButton from "../../Components/Buttons/DemandingBookButton/DemandingBookButton";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { GetHome } from "../../Services/Home/Home";
import { UseUser } from "../../Context/UserContext";
import { GetBooksByLocation, GetBooksBySearch } from "../../Services/Book/Book";
import "./HomePage.css"; // Assuming loader CSS is added here
import Loader from "../../Components/Loader/Loader";
import SearchBar from "../../Components/SearchBar/SearchBar";

const Alert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      variant="filled"
      autoHideDuration={4000}
      {...props}
    />
  );
});

const HomePage = () => {
  const [feedBooks, setFeedBooks] = useState([]);
  const [demandBooks, setDemandBooks] = useState([]);
  const [loading, setLoading] = useState(true); // Loader state
  const [searchTerm, setSearchTerm] = useState("");
  const { user } = UseUser();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snakBarMessage, setSnakBarMessage] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (location.state?.message) {
      setSnakBarMessage(location.state.message);
      setSnackbarOpen(true);
      navigate("/", { replace: true, state: {} }); // This clears the state
    }
  }, [location.state, navigate]);

  const handleSearch = async () => {
    setLoading(true); // Set loading before API call
    if (searchTerm) {
      const res = await GetBooksBySearch(searchTerm);

      if (res.status === 200) {
        const filteredBooks = res.data.data.filter((book) => !book.isOnDemand);
        setFeedBooks(filteredBooks);
        const filteredDemandBooks = res.data.data.filter(
          (book) => book.isOnDemand
        );
        setDemandBooks(filteredDemandBooks);
      } else if (res.status === 204) {
        setDemandBooks([]);
        setFeedBooks([]);
      }
      setLoading(false); // Set loading off after data is fetched
    }
  };

  async function getData() {
    const res = await GetHome();
    if (res.status === 200) {
      const filteredBooks = res.data.data.books.filter(
        (book) => book.isOnDemand === false
      );
      setFeedBooks(filteredBooks);

      const filteredDemandBooks = res.data.data.books.filter(
        (book) => book.isOnDemand === true
      );
      setDemandBooks(filteredDemandBooks);
    } else if (res.status === 429) {
      setSnackbarOpen(true);
      setSnakBarMessage("Please try later");
    }
    setLoading(false);
  }

  useEffect(() => {
    async function getHomeData() {
      setLoading(true); // Set loading before data fetching
      try {
        if (user && user.hasOwnProperty("location")) {
          const res = await GetBooksByLocation(
            user.location.coordinates[0],
            user.location.coordinates[1]
          );
          if (res.status === 200) {
            const filteredBooks = res.data.data.filter(
              (book) => book.isOnDemand === false
            );
            setFeedBooks(filteredBooks);
            const filteredDemandBooks = res.data.data.filter(
              (book) => book.isOnDemand === true
            );
            setDemandBooks(filteredDemandBooks);
          } else {
            await getData();
          }
        } else {
          await getData();
        }
      } catch (error) {
        setLoading(false); // Ensure loader stops on error
      }
    }
    getHomeData();
  }, [user]);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      {loading ? ( // Show loader while loading
        <Loader />
      ) : (
        <div className="homepage">
          <div className="hero-section">
            {/* Hero Content */}
            <div className="hero-content d-flex align-items-center justify-content-between">
              <div className="hero-text">
                <h2>Best Place to Sell & Find Your </h2>
                <h2>Favorite Books</h2>
                <p className="my-4">
                  A book is a medium for recording information in the form of
                  writing or images.
                </p>
                <SearchBar
                  searchTerm={searchTerm}
                  handleSearch={handleSearch}
                  setSearchTerm={setSearchTerm}
                />
              </div>
              <div className="hero-img">
                <img src={require("../../Assets/hero-banner.png")} alt="" />
              </div>
            </div>
          </div>

          {/* Books Feed */}
          <div className="books-feed-header d-flex align-items-center justify-content-between  gap-2 ">
            <h3 className="text">Books Feed</h3>
            <Link to="/selling-books-library">
              <GradientButton>See All</GradientButton>
            </Link>
          </div>

          {feedBooks.length === 0 ? (
            <p className="flex align-items-center justify-content-center">
              No books found matching your search.
            </p>
          ) : (
            <BookCarousal
              ButtonComponent={SellingBookCardButtons}
              book={feedBooks}
            />
          )}

          {/* Book Categories */}
          <div className="books-feed-header">
            <h3 className="text mb-4">Book Categories</h3>
          </div>
          <div
            style={{
              background:
                "linear-gradient(white 55%, rgb(163, 213, 255, 1) 45%)",
            }}
          >
            <TypesCarousel />
          </div>

          {/* Demanding Books */}
          <div className="books-feed-header mt-4 mb-1 d-flex align-items-center justify-content-between  gap-2 ">
            <h3 className="text">Demanding Books</h3>
            <Link to="/demanding-books-library">
              <GradientButton>See All</GradientButton>
            </Link>
          </div>

          {demandBooks.length === 0 ? (
            <p className="flex align-items-center justify-content-center">
              No books found matching your search.
            </p>
          ) : (
            <BookCarousal
              ButtonComponent={DemandingBookButton}
              book={demandBooks}
            />
          )}

          {/* Tutor Section */}
          <div className="hp-online-tutor py-5">
            <div className="text text-center d-flex flex-column align-items-center justify-content-around">
              <h3>Online Tutor</h3>
              <p className="fs-6">
                A book is a medium for recording information in the form of
                writing or images.
              </p>
            </div>
            <TutorCarousel />
          </div>

          {/* Snackbar */}
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={1000}
            onClose={handleSnackbarClose}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert onClose={handleSnackbarClose}>{snakBarMessage}</Alert>
          </Snackbar>
        </div>
      )}
    </>
  );
};

export default HomePage;
