// BookCarousal.js
import React, { useState, useEffect } from "react";
import Slider from "react-slick"; // Importing Slider from react-slick
import "./BookCarousal.css";
import BookCard from "../BookCard/BookCard";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { UseUser } from "../../Context/UserContext";

export default function BookCarousal({
  onViewDetails,
  onAddToCart,
  ButtonComponent,
  book,
}) {
  const [books, setBooks] = useState([]);
  const [backgroundColors, setBackgroundColors] = useState({});
  const { user, setUser } = UseUser();

  useEffect(() => {
    async function fetchData() {
      if (book) {
        const colorPromises = book.map(async (Book) => {
          const color = getRandomColor();
          return { id: Book._id, color };
        });

        const colors = await Promise.all(colorPromises);
        const colorMap = {};
        colors.forEach(({ id, color }) => {
          colorMap[id] = color;
        });

        setBooks(book);
        setBackgroundColors(colorMap);
      }
    }
    // return () => {
    fetchData();
    // };
  }, [book]);

  const getRandomColor = () => {
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);
    return `rgba(${r}, ${g}, ${b}, 0.35)`;
  };

  const bookTemplate = (book) => {
    const backgroundColor = backgroundColors[book._id] || "rgba(0, 0, 0, 0.1)"; // Default color if not yet loaded

    return (
      <BookCard
        key={book._id}
        book={book}
        backgroundColor={backgroundColor}
        onViewDetails={onViewDetails}
        onAddToCart={onAddToCart}
        ButtonComponent={ButtonComponent}
        user={user}
        setUser={setUser}
      />
    );
  };

  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 1500,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1650,
        settings: { slidesToShow: 3 },
      },
      {
        breakpoint: 1350,
        settings: { slidesToShow: 3 },
      },
      {
        breakpoint: 1010,
        settings: { slidesToShow: 2 },
      },
      {
        breakpoint: 680,
        settings: { slidesToShow: 1 },
      },
    ],
  };

  return (
    <div className="book-carousel-card">
      <Slider {...settings}>{books.map(bookTemplate)}</Slider>
    </div>
  );
}
