// import axios from "axios";
// axios.defaults.withCredentials = true
// const instance = axios.create({
//   baseURL: "https://api.booksbrary.com/api/v1/",
// });

// instance.interceptors.request.use(
//     (config) => {
//         // Check if the data is an instance of FormData to set content-type as 'multipart/form-data'
//         if (config.data instanceof FormData) {
//             config.headers['Content-Type'] = 'multipart/form-data';
//         } else {
//             // Default to application/json if not FormData
//             config.headers['Content-Type'] = 'application/json';
//         }

//         return config;
//     },
//     (error) => {
//         // Log request error for debugging
//         console.error("Request Error:", error);
//         return Promise.reject(error);
//     }
// );

// instance.interceptors.response.use(
//     (response) => {
//         return response;
//     },
//     (error) => {

//         // Return a consistent error format
//         return Promise.reject({
//             status: error.response?.status || 500,
//             message: error.response?.data?.message || error.message || 'An unknown error occurred',
//         });
//     }
// );

// export default instance;
import axios from "axios";

axios.defaults.withCredentials = true;

const instance = axios.create({
  baseURL: "https://api.booksbrary.com/api/v1/", // Default base URL
});

instance.interceptors.request.use(
  (config) => {
    // Set headers dynamically based on data type
    if (config.data instanceof FormData) {
      config.headers["Content-Type"] = "multipart/form-data";
    } else {
      config.headers["Content-Type"] = "application/json";
    }
    return config;
  },
  (error) => {
    console.error("Request Error:", error);
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // Check if the request failed and hasn't been retried yet
    if (
      !originalRequest._retry &&
      error.response?.status !== 404 &&
      error.response?.status !== 401 &&
      error.response?.status !== 400
    ) {
      originalRequest._retry = true; // Mark the request as retried
      console.warn("Retrying request with fallback URL...");

      // Set fallback base URL
      originalRequest.baseURL =
        "https://booksbrary.ap-south-1.elasticbeanstalk.com/api/v1/";

      // Retry the request with the updated base URL
      try {
        return await instance(originalRequest);
      } catch (fallbackError) {
        console.error("Fallback URL also failed:", fallbackError.message);
        return Promise.reject(fallbackError);
      }
    }

    // If no retry or fallback, propagate the error
    return Promise.reject({
      status: error.response?.status || 500,
      message:
        error.response?.data?.message ||
        error.message ||
        "An unknown error occurred",
    });
  }
);

export default instance;
