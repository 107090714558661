import React, { useState, useEffect } from "react";
import { GoogleMap, LoadScriptNext, Marker } from "@react-google-maps/api";
import { TextField } from "@mui/material";

const mapContainerStyle = {
  height: "400px",
  width: "100%",
};

const center = {
  lat: 34.0522, // Initial latitude
  lng: -118.2437, // Initial longitude
};

const Map = ({ fetchCoordinates, handleLocationModalClose }) => {
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [map, setMap] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [predictions, setPredictions] = useState([]);

  useEffect(() => {
    if (inputValue.length > 0) {
      const autocompleteService =
        new window.google.maps.places.AutocompleteService();

      // Fetch predictions from Google's Places Autocomplete service
      autocompleteService.getPlacePredictions(
        {
          input: inputValue,
          types: ["(regions)"],
        },
        (predictions, status) => {
          if (
            status === window.google.maps.places.PlacesServiceStatus.OK &&
            predictions
          ) {
            setPredictions(predictions);
          }
        }
      );
    }
  }, [inputValue]);

  const handlePlaceSelect = async (placeId, description) => {
    const service = new window.google.maps.places.PlacesService(map);

    // Use PlacesService to get details for the selected place
    service.getDetails({ placeId }, (place, status) => {
      if (
        status === window.google.maps.places.PlacesServiceStatus.OK &&
        place &&
        place.geometry
      ) {
        const location = place.geometry.location;
        setSelectedPlace({
          lat: location.lat(),
          lng: location.lng(),
        });
        fetchCoordinates(description);
        setInputValue(description);
        map.panTo({ lat: location.lat(), lng: location.lng() });
      }
    });
    setTimeout(() => {
      handleLocationModalClose();
    }, 1000);
  };

  return (
    <LoadScriptNext
      googleMapsApiKey={process.env.REACT_APP_API_KEY}
      libraries={["places"]}
    >
      <div style={{ margin: "10px", padding: "10px" }}>
        <TextField
          label="Enter Location"
          variant="outlined"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)} // Track user input
          sx={{
            width: {
              xs: "100%", // Full width on extra small and small screens
              sm: "100%", // Full width on small screens
              md: "300px", // Specific width on medium and larger screens
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "blue", // Set the border color to blue
              },
              "&:hover fieldset": {
                borderColor: "blue", // Blue border on hover
              },
              "&.Mui-focused fieldset": {
                borderColor: "blue", // Blue border when focused
              },
            },
          }}
        />

        <ul style={{ paddingLeft: "0px" }}>
          {predictions.map((prediction) => (
            <li
              key={prediction.place_id}
              onClick={() =>
                handlePlaceSelect(prediction.place_id, prediction.description)
              }
              className=" flex gap-2"
              style={{
                cursor: "pointer",
                listStyleType: "none",
                margin: "5px 0",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z" />
              </svg>

              {prediction.description}
            </li>
          ))}
        </ul>
      </div>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={center}
        zoom={10}
        onLoad={(mapInstance) => setMap(mapInstance)}
      >
        {selectedPlace && <Marker position={selectedPlace} />}
      </GoogleMap>
    </LoadScriptNext>
  );
};

export default Map;
