function SearchBar({ searchTerm, setSearchTerm, handleSearch }) {
  return (
    <div className="hero-search-field">
      <input
        type="text"
        placeholder="Search book here"
        className="fs-4 fw-medium"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{ opacity: "0.5", letterSpacing: "-1.5px" }}
      />
      <button className="big-search-button" onClick={handleSearch}>
        <i
          className="fa-solid fa-magnifying-glass pe-2"
          style={{ color: "#ffffff" }}
        ></i>
        Search
      </button>
    </div>
  );
}

export default SearchBar;
