import React from "react";
import "./PrivacyPolicy.css"; // You can link a CSS file for further styling

const PrivacyPolicy = () => {
  return (
    <div>
      <div className="privacy-policy-section">
        <div className="privacy-header">
          <h2 className="privacy-title">Privacy Policy</h2>
          <p className="privacy-subtitle">
            Welcome to Books Brary, your haven for library treasures! We are
            passionate about bringing readers closer to the stories they love,
            whether they’re classic tales, modern masterpieces, or hidden gems
            waiting to be discovered.
          </p>
        </div>
        <img src="./privacy.png" alt="" />
      </div>
      <div className="privacy-content">
        <div className="privacy-item">
          <h3>1. Introduction</h3>
          <ul>
            <li>
              Welcome to Books Brary! This privacy policy outlines the data
              collection and use practices for our website.
            </li>
            <li>
              We are committed to protecting your privacy and ensuring a secure
              online experience.
            </li>
          </ul>
        </div>

        <div className="privacy-item">
          <h3>2. Information Collection</h3>
          <ul>
            <li>
              {" "}
              When you register on our site, place an order,, we may collect
              personal information such as your name, email address, mailing
              address, phone number, and payment details.
            </li>

            <li>
              We didn't collect non-personal information, including your browser
              type, device information, IP address, and browsing patterns.
            </li>
            <li>Our website didn't uses cookies.</li>
          </ul>
        </div>

        <div className="privacy-item">
          <h3>3. Use of Information</h3>
          <ul>
            <li>
              The information we collect is used to provide and improve our
              services. This includes processing orders, personalizing your
              experience, and enhancing our website’s functionality.
            </li>
            <li>
              We may use your information to send newsletters, updates, and
              promotional materials. You can opt out of these communications at
              any time.
            </li>
          </ul>
        </div>

        <div className="privacy-item">
          <h3>4. Children's Privacy</h3>
          <ul>
            <li>
              Books Brary is committed to protecting children’s privacy. We do
              not knowingly collect personal information from children under a
              certain age. If we learn we have collected such information, we
              will take steps to delete it promptly.
            </li>
          </ul>
        </div>

        <div className="privacy-item">
          <h3>5. Changes to the Privacy Policy</h3>
          <ul>
            <li>We may update this privacy policy from time to time.</li>
            <li>
              Any changes will be posted on this page, and continued use of our
              site after changes are made will constitute acceptance of the new
              policy.
            </li>
          </ul>
        </div>
        <div className="privacy-item">
          <h3>6. Contact Information</h3>
          <ul>
            <li>
              If you have any questions or concerns about our privacy policy,
              please contact us at:
            </li>
            <li>Email: booksbraryofficial@gmail.com</li>
            <li>Phone: +923110507800</li>
            <li>
              Address: Main Morgah Mor, Near Ayub National Park, Rawalpindi
              Pakistan
            </li>
          </ul>
          Thank you for trusting Books Brary with your personal information. We
          are committed to ensuring your privacy and security.
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
