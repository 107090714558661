import React, { useState, useEffect } from "react";
import TutorServiceDraftCard from "../../Components/TutorServiceDraftCard/TutorServiceDraftCard";
import Pagination from "../../Components/Pagination/Pagination";
import "./TutorServiceDraftPage.css";
import { GetMyServices } from "../../Services/tutorServices/Services";
import Loader from "../../Components/Loader/Loader";
import PositionedSnackbar from "../../Components/Snackbar/Snackbar";
import { useNavigate } from "react-router-dom";

export default function TutorServiceDraftPage() {
  const [tutors, setTutors] = useState([]);
  const [visibleTutors, setVisibleTutors] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const tutorsPerPage = 4;
  const [loading, setLoading] = useState(false); // Loading state
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const navigate = useNavigate();
  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        const fetchedTutors = await GetMyServices();

        if (fetchedTutors.status === 200) {
          setTutors(fetchedTutors.data.data);
          setVisibleTutors(fetchedTutors.data.data.slice(0, tutorsPerPage));
        } else if (fetchedTutors.status === 401) {
          setLoading(false);
          localStorage.removeItem("user");
          localStorage.removeItem("token");
          navigate("/login");
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, [navigate]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    const startIndex = (page - 1) * tutorsPerPage;
    const newTutors = tutors.slice(startIndex, startIndex + tutorsPerPage);
    setVisibleTutors(newTutors);
  };

  const totalPages = Math.ceil(tutors.length / tutorsPerPage);
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  return (
    <>
      {" "}
      <PositionedSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        handleClose={handleSnackbarClose}
      />
      {loading ? (
        <Loader />
      ) : (
        <>
          {visibleTutors.length === 0 ? (
            <div className="screen-size">No Tutor found</div>
          ) : (
            <div className="tutor-ads-container">
              <div className="tutor-ads-grid">
                {visibleTutors.map((tutor) => (
                  <TutorServiceDraftCard
                    key={tutor._id}
                    tutor={tutor}
                    setTutors={setTutors}
                    setVisibleTutors={setVisibleTutors}
                    setSnackbarOpen={setSnackbarOpen}
                    setSnackbarMessage={setSnackbarMessage}
                    setSnackbarSeverity={setSnackbarSeverity}
                  />
                ))}
              </div>
              {totalPages > 1 && (
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              )}
            </div>
          )}
        </>
      )}
    </>
  );
}
