import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, Link } from "react-router-dom";
import PositionedSnackbar from "../../Components/Snackbar/Snackbar";
import "./SignUpWith.css";
import { signUp } from "../../Services/Auth/Auth";

const NewAccount = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();
  const navigate = useNavigate();
  const password = watch("password");

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // Snackbar state
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const onSubmit = async (data) => {
    try {
      if (data.name === "" || data.password === "" || data.email === "") {
        setSnackbarMessage("Fill all input fields");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        return;
      }

      const res = await signUp(data);
      if (res.status === 400) {
        setSnackbarMessage(data.message);
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        return;
      }
      if (res.status === "success") {
        setSnackbarMessage("Registration successful!");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);

        setTimeout(() => {
          navigate("/login");
        }, 2000);
      } else {
        setSnackbarMessage("Registration failed. Please try again.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Error:", error);
      setSnackbarMessage("An error occurred. Please try again.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  return (
    <div className="sign-up-bg d-flex align-items-center justify-content-center">
      {/* Snackbar component */}
      <PositionedSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        handleClose={handleCloseSnackbar}
      />
      <div className="sign-up-container d-flex align-items-center justify-content-center flex-column p-4">
        <div className="create-new-account-text pt-2">
          <p className="welcome-back text-center m-0 p-0 fs-4 fw-bold">
            Create Account
          </p>
          <p className="text-center m-0 p-0 mb-3">
            First you have to Signup your account
          </p>
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="create-new-account-form"
        >
          {/* Name Field */}
          <div className="input-group d-flex flex-column">
            <div className="input-container">
              <i
                className="fa-regular fa-user fa-xl"
                style={{ color: "#1877f2" }}
              ></i>
              <input
                className={`form-control rounded-4 ${
                  errors.name ? "is-invalid" : ""
                }`}
                type="text"
                placeholder="Enter Your Name"
                {...register("name", { required: "Name is required" })}
              />
            </div>
            {errors.name ? (
              <span className="error-message text-danger m-0 ms-1 mb-2">
                {errors.name.message}
              </span>
            ) : (
              <div className="empty-div my-1 py-1"></div>
            )}
          </div>

          {/* Email Field */}
          <div className="input-group d-flex flex-column">
            <div className="input-container">
              <i
                className="fa-regular fa-envelope fa-xl"
                style={{ color: "#1877f2" }}
              ></i>
              <input
                className={`form-control rounded-4 ${
                  errors.email ? "is-invalid" : ""
                }`}
                type="text"
                placeholder="Enter Your Email Address"
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                    message: "Invalid email address",
                  },
                })}
              />
            </div>
            {errors.email ? (
              <span className="error-message text-danger m-0 ms-1 mb-2">
                {errors.email.message}
              </span>
            ) : (
              <div className="empty-div my-1 py-1"></div>
            )}
          </div>

          {/* Password Field */}
          <div className="input-group d-flex flex-column">
            <div className="input-container">
              <i
                className="fa-solid fa-lock fa-xl"
                style={{ color: "#1877f2" }}
              ></i>
              <input
                className={`form-control password-input rounded-4 ${
                  errors.password ? "is-invalid" : ""
                }`}
                type={showPassword ? "text" : "password"} // Toggle input type
                placeholder="Enter Password"
                {...register("password", {
                  required: "Password is required",
                  minLength: {
                    value: 6,
                    message: "Password must be at least 6 characters",
                  },
                })}
              />
              <i
                className={`the-eye-button fa ${
                  showPassword ? "fa-eye" : "fa-eye-slash"
                }`}
                onClick={togglePasswordVisibility}
              ></i>
            </div>
            {errors.password ? (
              <span className="error-message text-danger m-0 ms-1 mb-2">
                {errors.password.message}
              </span>
            ) : (
              <div className="empty-div my-1 py-1"></div>
            )}
          </div>

          {/* Confirm Password Field */}
          <div className="input-group d-flex flex-column">
            <div className="input-container">
              <i
                className="fa-solid fa-lock fa-xl"
                style={{ color: "#1877f2" }}
              ></i>
              <input
                className={`form-control password-input rounded-4 ${
                  errors.confirmPassword ? "is-invalid" : ""
                }`}
                type={showConfirmPassword ? "text" : "password"} // Toggle input type
                placeholder="Confirm Password"
                {...register("confirmPassword", {
                  required: "Please confirm your password",
                  validate: (value) =>
                    value === password || "Passwords do not match",
                })}
              />
              <i
                className={`the-eye-button fa ${
                  showConfirmPassword ? "fa-eye" : "fa-eye-slash"
                }`}
                onClick={toggleConfirmPasswordVisibility}
              ></i>
            </div>
            {errors.confirmPassword ? (
              <span className="error-message text-danger m-0 ms-1 mb-2">
                {errors.confirmPassword.message}
              </span>
            ) : (
              <div className="empty-div my-1 py-1"></div>
            )}
          </div>

          {/* Create Account Button */}
          <div className="create-account-button">
            <button
              type="submit"
              className="submit-button btn btn-primary w-100 border-0 fw-bold fs-5 my-2 rounded-3 py-2"
            >
              Sign Up
            </button>
          </div>
          <div
            className="d-flex justify-content-center"
            style={{ fontSize: "14px" }}
          >
            <span className="my-2 m-0 d-inline">
              Already have an account? &nbsp;
            </span>
            <Link to="/login">
              <span className="forgot-password d-flex justify-content-center italic my-2">
                Login
              </span>
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewAccount;
