import React from "react";
import "./ProfileOptions.css";
import Button from "react-bootstrap/Button";
import { Link, useNavigate } from "react-router-dom";
import { UseUser } from "../../Context/UserContext";
import { logout } from "../../Services/Auth/Auth";

const ProfileOptions = ({ closeOption }) => {
  const { user, setUser } = UseUser();
  const navigate = useNavigate();

  const navigste = useNavigate();
  const handleLogout = async () => {
    const res = await logout();
    if (res.status === 200) {
      setUser(null);
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      navigate("/");
    } else if (res.status === 401) {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      navigate("/login");
    }
  };

  return (
    <div>
      <div className="profile-options-container d-flex flex-column gap-3 gap-md-3 me-2">
        <div className="user-name-image d-flex align-items-center justify-content-start gap-4">
          {user.profileImage && user.profileImage !== "" ? (
            <img className="user-image w-25" src={user.profileImage} alt="" />
          ) : (
            <div className="profile-image-size">
              <div className="profile-image-before  d-flex align-items-center justify-content-center">
                <i
                  className="fa-solid fa-user"
                  style={{ color: "white", fontSize: "40px" }}
                ></i>
              </div>
            </div>
          )}
          <div className="user-name">
            <p className="m-0 mobile-text-size">Hello</p>
            <p className="m-0 fw-medium mobile-text-size">{user.name}</p>
          </div>
        </div>

        <Link to="/edit-profile" onClick={() => closeOption(false)}>
          <Button
            variant="outline-primary"
            className="edit-profile-button fw-light w-100 fs-6 rounded-3 py-2 mobile-text-size"
          >
            View and edit your profile
          </Button>
        </Link>
        <Link
          to="/my-book-ads"
          onClick={() => closeOption(false)}
          style={{ textDecoration: "none", color: "black" }}
        >
          <div className="profile-option-items d-flex align-items-center justify-content-start gap-4 mobile-text-size">
            <i
              className="fa-solid fa-bookmark rounded-2 d-flex align-items-center justify-content-center"
              style={{
                backgroundColor: "#0184f0",
                color: "#ffffff",
                width: "35px",
                height: "30px",
              }}
            />
            <p className="m-0 mobile-text-size">My Book Ads</p>
          </div>
        </Link>
        <Link
          to="/favourite-and-saved"
          onClick={() => closeOption(false)}
          style={{ textDecoration: "none", color: "black" }}
        >
          <div className="profile-option-items d-flex align-items-center justify-content-start gap-4 mobile-text-size">
            <i
              className="fa-solid fa-heart rounded-2 d-flex align-items-center justify-content-center"
              style={{
                backgroundColor: "#0184f0",
                color: "#ffffff",
                width: "35px",
                height: "30px",
              }}
            />
            <p className="m-0">Favorites and saved</p>
          </div>
        </Link>
        <Link
          to="/tutor-service-draft-page"
          onClick={() => closeOption(false)}
          style={{ textDecoration: "none", color: "black" }}
        >
          <div className="profile-option-items d-flex align-items-center justify-content-start gap-4 mobile-text-size">
            <i
              className="fa-solid fa-user rounded-2 d-flex align-items-center justify-content-center"
              style={{
                backgroundColor: "#0184f0",
                color: "#ffffff",
                width: "35px",
                height: "30px",
              }}
            />
            <p className="m-0">My Services</p>
          </div>
        </Link>
        <div
          className="profile-option-items d-flex align-items-center justify-content-start gap-4 mobile-text-size"
          onClick={() => {
            navigste("/update-password");
            closeOption(false);
          }}
        >
          <i
            className="fa-solid fa-pen-to-square rounded-2 d-flex align-items-center justify-content-center"
            style={{
              backgroundColor: "#0184f0",
              color: "#ffffff",
              width: "35px",
              height: "30px",
              fontSize: "18px",
            }}
          />
          <p className="m-0">Update Password</p>
        </div>

        {/* <Link
          to="/my-book-demand"
          style={{ textDecoration: "none", color: "black" }}
        >
          <div className="profile-option-items d-flex align-items-center justify-content-start gap-4 mobile-text-size">
            <i
              className="fa-solid fa-book rounded-2 d-flex align-items-center justify-content-center"
              style={{
                backgroundColor: "#0184f0",
                color: "#ffffff",
                width: "35px",
                height: "30px",
              }}
            />
            <p className="m-0">Demanding Books</p>
          </div>
        </Link> */}
        <hr className="m-0" />
        <div className="profile-option-items d-flex align-items-center justify-content-start gap-4 mobile-text-size">
          <i
            className="fa-solid fa-arrow-right-from-bracket rounded-2 d-flex align-items-center justify-content-center"
            style={{
              backgroundColor: "red",
              color: "#ffffff",
              width: "35px",
              height: "35px",
            }}
          />
          <button
            className="m-0"
            onClick={handleLogout}
            style={{ color: "red" }}
          >
            Logout
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProfileOptions;
