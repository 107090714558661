export const fetchCityName = async (coordinates, setCity) => {
  const [lat, lng] = coordinates;

  const googleApiKey = process.env.REACT_APP_API_KEY; // Use your Google Maps API key here

  try {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${googleApiKey}`
    );
    const data = await response.json();

    if (data.status === "OK") {
      // Loop through the results to find the 'locality' (city) component
      const addressComponents = data.results[0].address_components;
      let city = "";

      addressComponents.forEach((component) => {
        if (component.types.includes("locality")) {
          city = component.long_name;
        }
      });

      if (city) {
        setCity(city);
      } else {
        setCity("City not found");
      }
    } else {
      setCity("Location not found");
    }
  } catch (error) {
    console.error("Error fetching city name:", error);
    setCity("Error fetching location");
  }
};
