import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCChxS7S7gjRZdQCILKuv8rn0DU9z4rozU",
  authDomain: "books-brary-c7b75.firebaseapp.com",
  projectId: "books-brary-c7b75",
  storageBucket: "books-brary-c7b75.appspot.com",
  messagingSenderId: "485959516055",
  appId: "1:485959516055:web:0aae1808f443ce62e20ed2",
  measurementId: "G-M7Q6VNWG8P"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
