import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./TutorDetails.css";
import Rating from "@mui/material/Rating";
import Button from "react-bootstrap/Button";
import CustomModal from "../../Components/CustomModal/CustomModal";
import {
  CreateTutorReview,
  GetOneServices,
} from "../../Services/tutorServices/Services";
import { formatDate } from "../../libs/FormateTime";
import { calculateAverageRating } from "../../libs/calculateAvgerageRating";
import { fetchCityName } from "../../libs/formateLocation";
import Loader from "../../Components/Loader/Loader";
import { TextField } from "@mui/material";
import PositionedSnackbar from "../../Components/Snackbar/Snackbar";
import { UseUser } from "../../Context/UserContext";
import moment from "moment";
import ShowMoreText from "react-show-more-text";

const TutorDetails = () => {
  const { user } = UseUser();
  const navigate = useNavigate();
  const location = useLocation();
  const { tutorId } = location.state || {};
  const [tutor, setTutor] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [city, setCity] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [review, setReview] = useState({
    rating: 0.5,
    message: "",
  });
  useEffect(() => {
    if (tutorId) {
      GetOneServices(tutorId)
        .then((data) => {
          if (data.status === 200) {
            setTutor(data.data.data);
            fetchCityName(data.data.data.user_id.location.coordinates, setCity);
          }
        })
        .catch((error) => console.error("Error fetching tutor data:", error));
    }
  }, [tutorId]);
  console.log(tutor);

  const handleRatingChange = (event, newValue) => {
    setReview((prev) => ({
      ...prev,
      rating: newValue,
    }));
  };
  const handleInputChange = (event) => {
    setReview((prev) => ({
      ...prev,
      message: event.target.value,
    }));
  };
  const handleRatingClick = () => {
    setIsModalOpen(true); // Open the modal
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); // Close the modal
  };

  if (!tutor) {
    return <Loader />;
  }

  const fromTime = formatDate(tutor.availability.from);
  const toTime = formatDate(tutor.availability.to);
  const averageRating = calculateAverageRating(tutor);

  const handleButtonClick = async () => {
    if (review.message.trim() === "") {
      setSnackbarMessage("Please fill review field");
      setSnackbarOpen(true);
      setSnackbarSeverity("error");
      return;
    }
    if (user) {
      const data = {
        rating: review.rating,
        message: review.message,
      };

      const response = await CreateTutorReview(tutorId, data);

      if (response.status === 200) {
        const newReview = {
          userName: user.name,
          rating: review.rating,
          description: review.message,
          createdAt: new Date().toISOString(),
          profileImage: user.profileImage,
        };

        // Add the new review to the book's reviews
        setTutor((prevTutor) => ({
          ...prevTutor,
          reviews: [newReview, ...prevTutor.reviews],
        }));

        // Show success message
        setSnackbarMessage("Review submitted successfully!");
        setSnackbarOpen(true);
        setSnackbarSeverity("success");

        // Close the modal after submission
        setIsModalOpen(false);

        // Reset the rating state
        setReview({
          rating: 0,
          message: "",
        });
      } else if (response.status === 401) {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        navigate("/login");
      } else {
        setSnackbarMessage("Failed to submit review. Please try again.");
        setSnackbarOpen(true);
        setSnackbarSeverity("error");
      }
    } else {
      navigate("/login");
    }
  };
  function ratingDtae(createdAt) {
    return moment(createdAt).format("MMMM YYYY");
  }
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
  function formatPhoneNumber(number) {
    // Check if the number starts with a '+' (indicating it already has a country code)
    if (!number.startsWith("+")) {
      // Add the default country code if it's missing
      const defaultCountryCode = "+92"; // Change this if needed
      number = defaultCountryCode + number.replace(/^0+/, ""); // Remove any leading zeroes
    }
    return number;
  }
  return (
    <div className="tutor-details-main-container">
      <div className="tutor-details-container d-flex align-items-center justify-content-evenly flex-column flex-md-row row-gap-5">
        <div className="tutor-details-image d-flex align-items-center justify-content-center rounded-4">
          {tutor.user_id.profileImage !== "" ? (
            <img
              src={tutor.user_id.profileImage}
              alt={tutor.name}
              className="rounded-4"
            />
          ) : (
            <div className="tutor-image-before tutor-image d-flex align-items-center justify-content-center">
              <i
                className="fa-solid fa-user"
                style={{ color: "white", fontSize: "80px" }}
              ></i>
            </div>
          )}
        </div>
        <div className="tutor-details-text text-start w-100 d-flex flex-column gap-2">
          <h3 className="tutor-details-title m-0 fw-bold fs-3 mobile-text-larger">
            {tutor.user_id.name}
          </h3>

          <div className="location-row d-flex align-items-center justify-content-between">
            <p className="tutor-details-price m-0 py-1 px-3 fs-6 fs-md-5 rounded-5 mobile-text-medium">
              Rs.{tutor.fees}
            </p>
            <div
              className="d-flex align-items-center"
              onClick={handleRatingClick}
            >
              <Rating
                size="small"
                name="half-rating-read"
                value={averageRating}
                precision={0.5}
                readOnly
              />
              <p
                className="m-0 ms-1"
                style={{ fontSize: "12px", color: "#faaf00" }}
              >
                {averageRating}/5
              </p>
            </div>
          </div>
          <p className="m-0 fw-medium mobile-text-medium">
            Email: {tutor.user_id.email}
          </p>
          <p className="m-0 fw-medium mobile-text-medium">
            Subject: {tutor.subject}
          </p>
          <p className="m-0 fw-medium mobile-text-medium">
            Language: {tutor.language}
          </p>
          <p className="m-0 fw-medium mobile-text-medium">
            Experience: {tutor.experience}
          </p>
          <p className="m-0 fw-medium mobile-text-medium">
            Qualification: {tutor.qualification}
          </p>
          <div className="location-row d-flex align-items-center justify-content-between">
            <p className="tutor-details-location m-0 fs-6 mobile-text-medium">
              <i
                className="fa-solid fa-location-dot me-2"
                style={{ color: "#000000" }}
              />
              {city || "Location not found"}
            </p>
            <p className="m-0 mobile-text-medium">
              {fromTime} - {toTime} Mon-Fri
            </p>
          </div>
        </div>
      </div>
      <div className="tutor-description-section fw-medium my-5">
        <h4 className="mb-2 mobile-text-large">Description</h4>
        <p className="m-0 fw-light mobile-text-small">
          {tutor.description} Lorem ipsum dolor sit, amet consectetur
          adipisicing elit. Unde fugit obcaecati repudiandae, debitis hic quidem
          laudantium temporibus soluta necessitatibus veniam consectetur
          cupiditate vero eveniet repellendus inventore nisi culpa maxime!
          Maiores beatae veritatis recusandae nesciunt assumenda hic veniam
          quaerat aliquid corrupti quos iure qui, laudantium repellendus
          deleniti exercitationem? Provident labore placeat ratione perferendis
          quibusdam explicabo voluptas exercitationem voluptates repellat id
          corporis excepturi saepe beatae, porro aut totam quo veniam illo.
          Dolorum, voluptas ut iusto rerum accusamus rem explicabo odit ad
          voluptates sequi totam ipsum suscipit possimus? Corporis, dolor neque
          exercitationem illum minus excepturi quod. Pariatur, nam esse aliquam
          tenetur architecto consectetur?
        </p>
      </div>
      <div className="d-flex align-items-center justify-content-center">
        <a
          href={`https://wa.me/${formatPhoneNumber(tutor.phoneNumber)}`}
          target="_blank"
          className="sale-now-button fs-6 rounded-4 w-100 py-3 d-flex align-items-center justify-content-center text-white"
          rel="noreferrer"
        >
          <i className="fa-regular fa-comment-dots me-2" />
          Chat
        </a>
      </div>

      {/* Render the CustomModal component with children */}
      <CustomModal isOpen={isModalOpen} onClose={handleCloseModal}>
        <h4 className="ms-2 mt-2">Give Review</h4>
        <div className="ms-2 mt-2 mb-4 custom-review-box">
          <Rating
            size="large"
            name="half-rating-read"
            value={review.rating}
            precision={0.5}
            onChange={handleRatingChange}
          />
          <div className="review-field">
            <TextField
              label="Enter your Review"
              variant="outlined"
              value={review.message}
              onChange={handleInputChange}
              sx={{
                width: {
                  xs: "100%", // Full width on extra small and small screens
                  sm: "100%", // Full width on small screens
                  md: "300px", // Specific width on medium and larger screens
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "blue", // Set the border color to blue
                  },
                  "&:hover fieldset": {
                    borderColor: "blue", // Blue border on hover
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "blue", // Blue border when focused
                  },
                },
              }}
            />
            <Button
              variant="primary"
              className="sale-now-button fs-6 rounded-4 py-3  custom-button"
              onClick={handleButtonClick}
            >
              Submit
            </Button>
          </div>
        </div>

        {tutor.reviews.filter(
          (review) => review && review.userName && review.description
        ).length > 0 && <h4 className="ms-2 mt-2">Book Reviews</h4>}
        <div
          className="review-section p-0 max-w-50"
          style={{
            overflowY: "auto",
            height:
              tutor.reviews.filter(
                (review) => review && review.userName && review.description
              ).length > 0 && "10rem",
          }}
        >
          {tutor.reviews
            .filter((review) => review && review.userName && review.description)
            .map((review, index) => (
              <div key={index} className="book-review-item p-2">
                <div className="d-flex align-items-center column-gap-3 justify-content-start ">
                  <img
                    src={review.profileImage}
                    alt={review.userName}
                    className="review-image rounded-circle align-self-start"
                    style={{ width: "45px", height: "45px" }}
                  />
                  <div className="">
                    <p className="m-0 fw-bold fs-6 mobile-text-small">
                      {review.userName}
                    </p>
                    <p className="m-0 mobile-text-small">
                      Review Date: <i>{ratingDtae(review.createdAt)}</i>
                    </p>
                  </div>
                </div>
                <div
                  className="review-paragraph"
                  style={{ paddingLeft: "60px" }}
                >
                  <Rating
                    className=" mt-2"
                    size="small"
                    name={`rating-${index}`}
                    value={review.rating}
                    precision={0.1}
                    readOnly
                  />
                  <ShowMoreText
                    lines={2}
                    more="Show more"
                    less="...Show less"
                    className="content-css mobile-text-small"
                    anchorClass="show-more-less-clickable"
                    expanded={false}
                    truncatedEndingComponent={" ..."}
                  >
                    <p className="p-0">{review.description}</p>
                  </ShowMoreText>
                </div>
              </div>
            ))}
        </div>
      </CustomModal>

      <PositionedSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        handleClose={handleCloseSnackbar}
      />
    </div>
  );
};

export default TutorDetails;
