import instance from "../instant";

const login = async (data) => {
    try {
        const response = await instance.post('auth/login', JSON.stringify(data),
        {
            withCredentials: true 
          }    
        );

        return response;
    } catch (error) {
        return error;
    }
};
const loginWithGoogle = async (data) => {
    try {
        const response = await instance.post('auth/login-with-google', JSON.stringify(data),
        {
            withCredentials: true 
          }    
        );
        return response;
    } catch (error) {
        return error;
    }
};
const signUp = async (data) => {
    try {
        const response = await instance.post(
            'auth/register',
            JSON.stringify(data),  
           
        );
        return response.data;
    } catch (error) {
        if (error.response) {
            return error.response;
        } else if (error.request) {
            return error.request;
        } else if (error.message) {
            return error.message;
        }
    }
};

const logout = async () => {
    try {
        const headers = {};
        const token = JSON.parse(localStorage.getItem("token"));

        if (token !== null) {
            headers['Authorization'] = `Bearer ${token}`;
        }
        const response = await instance.post('auth/logout',{},{headers});
        return response;
    } catch (error) {
        return error;
    }
};

const GetUser = async (sessionId) => {
    try {
        const headers = {};
        if (sessionId !== null) {
            headers['Authorization'] = `Bearer ${sessionId}`;
        }
        const response = await instance.get('users/profile', { headers });
        return response;
    } catch (error) {
        return error;
    }
};
const UpdateProfile = async (data) => {
    try {
        const headers = {};
        const token = JSON.parse(localStorage.getItem("token"));

        if (token !== null) {
            headers['Authorization'] = `Bearer ${token}`;
        }
        const response = await instance.patch('users/update/profile', data,{ headers });
        return response;
    } catch (error) {
        return error;
    }
};

const forgotPassword = async (data) => {
    try {
        const headers = {};
        const token = JSON.parse(localStorage.getItem("token"));

        if (token !== null) {
            headers['Authorization'] = `Bearer ${token}`;
        }
        const response = await instance.post('auth/forgotpassword', data,{ headers });
        return response;
    } catch (error) {
        return error;
    }
};

const resetPassword = async (token,data) => {
    try {
      const jsonData = JSON.stringify(data);
      const response = await instance.post(
        `auth/resetpassword?token=${token}`,
        jsonData
      );
      return response;
    } catch (error) {
      return error;
    }
};

const UpdatePassword = async (data) => {
    try {
        const headers = {};
        const token = JSON.parse(localStorage.getItem("token"));

        if (token !== null) {
            headers['Authorization'] = `Bearer ${token}`;
        }
        const response = await instance.patch('users/updatepassword', data,{ headers });
        return response;
    } catch (error) {
        return error;
    }
};

const DeleteAccount = async () => {
    try {
      const headers = {}; 
      const token = JSON.parse(localStorage.getItem("token"));
  
      if (token !== null) {
        headers['Authorization'] = `Bearer ${token}`;
      }
  
      const response = await instance.delete(`users/deleteaccount`,{
        headers,
      });
      
      return response;
    } catch (error) {
      console.error("Error fetching books:", error);
      return error;
    }
};

const Updatefavourite = async (BookId) => {
    try {
      const headers = {}; 
      const token = JSON.parse(localStorage.getItem("token"));
  
      if (token !== null) {
        headers['Authorization'] = `Bearer ${token}`;
      }
  
      const response = await instance.put(`users/favourite`,BookId,{
        headers,
      });
      
      return response;
    } catch (error) {
      console.error("Error Favourite books:", error);
      return error;
    }
};
export { login, signUp, logout ,GetUser,UpdateProfile,loginWithGoogle,forgotPassword,resetPassword,UpdatePassword,DeleteAccount,Updatefavourite};