import instance from "../instant";

const CreateBookReview = async (formData) => {
    try {
        const headers = {}; 
        const token = JSON.parse(localStorage.getItem("token"));
      
        if (token !== null) {
            headers['Authorization'] = `Bearer ${token}`;
        }
        const response = await instance.post(`reviews/create`, formData, { headers });
        
        return response;
    } catch (error) {
        console.log(error);

        return error;
    }
};
export {CreateBookReview}