import React from "react";
import "./GradientButton.css";

const GradientButton = ({ children, ...props }) => {
  return (
    <button className="gradient-button" {...props}>
      {children}
    </button>
  );
};

export default GradientButton;
  