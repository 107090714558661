// BookButtons.js
import React from "react";
import Button from "react-bootstrap/Button";
import "./SellingBookCardButtons.css"; // Optional: Add specific styles for the buttons
// import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

const SellingBookCardButtons = ({
  bookId,
  backgroundColor,
  buttonText = "Buy Now",
  book,
}) => {
  const navigate = useNavigate();
  const handleViewDetails = () => {
    navigate("/book-details", {
      state: { bookId, backgroundColor, buttonText },
    });
  };
  function formatPhoneNumber(number) {
    // Check if the number starts with a '+' (indicating it already has a country code)
    if (!number.startsWith("+")) {
      // Add the default country code if it's missing
      const defaultCountryCode = "+92"; // Change this if needed
      number = defaultCountryCode + number.replace(/^0+/, ""); // Remove any leading zeroes
    }
    return number;
  }
  const handleButtonClick = () => {
    const whatsappUrl = `https://wa.me/${formatPhoneNumber(book.phoneNuber)}`;
    // Open the URL in a new tab
    window.open(whatsappUrl, "_blank");
  };
  return (
    <div className="third-row d-flex justify-content-between align-items-center">
      <Button
        onClick={handleViewDetails}
        variant="outline-primary"
        className="view-book-button mt-2 me-1 w-100 fs-md-5 fs-6"
      >
        View Details
      </Button>

      {book.phoneNuber && (
        <Button
          onClick={handleButtonClick}
          variant="primary"
          className="add-to-cart-button mt-2 ms-1 w-25 fs-md-5 fs-6"
        >
          <i className="fa-regular fa-comment-dots fa-lg"></i>
        </Button>
      )}
    </div>
  );
};

export default SellingBookCardButtons;
