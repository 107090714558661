import React from "react";
import "./Loader.css";
function Loader() {
  return (
    <div className="book-content">
      <div className="book-content-loader">
        <div className="loader">
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle"></div>
        </div>
      </div>
    </div>
  );
}

export default Loader;
