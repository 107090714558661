import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, Link, useLocation } from "react-router-dom";
import PositionedSnackbar from "../../Components/Snackbar/Snackbar";
import "./LoginPage.css";
import { login } from "../../Services/Auth/Auth";
import { UseUser } from "../../Context/UserContext";
import Loader from "../../Components/Loader/Loader";

const LoginPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [loading, setLoading] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const { setUser } = UseUser();
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (location.state?.registered) {
      setSnackbarOpen(true);
    }
  }, [location.state]);

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      if (data.email === "" || data.password === "") {
        setSnackbarMessage("Please fill all input fields");
        setSnackbarOpen(true);
        setSnackbarSeverity("error");
        setLoading(false);
        return;
      }
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            const locationData = {
              email: data.email,
              password: data.password,
              location: {
                type: "Point",
                coordinates: [
                  position.coords.latitude,
                  position.coords.longitude,
                ],
              },
            };
            await handleLogin(locationData);
          },
          async () => {
            const proceedWithoutLocation = window.confirm(
              "Location is disabled. Would you like to log in without location?"
            );
            if (proceedWithoutLocation) {
              const loginData = {
                email: data.email,
                password: data.password,
              };
              await handleLogin(loginData);
            } else {
              setSnackbarMessage("Please enable location to proceed");
              setSnackbarOpen(true);
              setLoading(false);
            }
          }
        );
      } else {
        setSnackbarMessage("Geolocation is not supported by your browser");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Login error:", error);
      setSnackbarMessage("An error occurred during login");
      setSnackbarSeverity("error");

      setSnackbarOpen(true);
      setLoading(false);
    }
  };

  // Separate function to handle login logic
  const handleLogin = async (loginData) => {
    const response = await login(loginData);
    console.log(response);
    
    if (response.status === 200) {
      setTimeout(async () => {
        const sessionId = JSON.stringify(response.data.sessionId);
        const sessionExpires = JSON.stringify(response.data.sessionExpires);
        localStorage.setItem("token", sessionId);
        localStorage.setItem("sessionExpires", sessionExpires);
        localStorage.setItem("user", JSON.stringify(response.data.user));
        setUser(response.data.user);
        setLoading(false);
        navigate("/", { state: { message: response.data.message } });
      }, 2000);
    } else if (response.status === 206) {
      setSnackbarMessage(response.data.message);
      setSnackbarSeverity("error");
      setLoading(false);

      setSnackbarOpen(true);
    } else {
      setSnackbarMessage(response.message);
      setSnackbarSeverity("error");

      setSnackbarOpen(true);
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="sign-up-bg d-flex align-items-center justify-content-center">
          {/* Snackbar component */}
          <PositionedSnackbar
            open={snackbarOpen}
            message={snackbarMessage}
            severity={snackbarSeverity}
            handleClose={handleCloseSnackbar}
          />
          <div className="sign-up-container d-flex align-items-center justify-content-center flex-column p-4">
            <div className="create-new-account-text pt-2">
              <p className="welcome-back text-center m-0 p-0 fs-4 fw-bold">
                Login Account
              </p>
              <p className="text-center m-0 p-0 mb-3">
                First you have to Login your account
              </p>
            </div>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="create-new-account-form"
            >
              {/* Email Field */}
              <div className="input-group d-flex flex-column">
                <div className="input-container">
                  <i
                    className="fa-regular fa-envelope fa-xl"
                    style={{ color: "#1877f2" }}
                  ></i>
                  <input
                    className={`form-control rounded-4 ${
                      errors.email ? "is-invalid" : ""
                    }`}
                    disabled={loading}
                    type="text"
                    placeholder="Enter Your Email Address"
                    {...register("email", {
                      required: "Email is required",
                      pattern: {
                        value:
                          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                        message: "Invalid email address",
                      },
                    })}
                  />
                </div>
                {errors.email ? (
                  <span className="error-message text-danger m-0 ms-1 mb-2">
                    {errors.email.message}
                  </span>
                ) : (
                  <div className="my-1 py-1"></div>
                )}
              </div>

              {/* Password Field */}
              <div className="input-group d-flex flex-column">
                <div className="input-container">
                  <i
                    className="fa-solid fa-lock fa-xl"
                    style={{ color: "#1877f2" }}
                  ></i>
                  <input
                    className={`form-control password-input rounded-4 ${
                      errors.password ? "is-invalid" : ""
                    }`}
                    disabled={loading}
                    type={showPassword ? "text" : "password"} // Toggle input type
                    placeholder="Enter Password"
                    {...register("password", {
                      required: "Password is required",
                      minLength: {
                        value: 6,
                        message: "Password must be at least 6 characters",
                      },
                    })}
                  />
                  <i
                    className={`the-eye-button fa ${
                      showPassword ? "fa-eye" : "fa-eye-slash"
                    }`}
                    onClick={togglePasswordVisibility}
                  ></i>
                </div>
                {errors.password ? (
                  <div className="d-flex justify-content-between">
                    <span className="error-message text-danger m-0 ms-1 mb-2">
                      {errors.password.message}
                    </span>
                    <Link to="/forgot-password">
                      <span
                        className="forgot-password d-flex justify-content-end italic my-2"
                        style={{ fontSize: "14px" }}
                      >
                        Forgot password?
                      </span>
                    </Link>
                  </div>
                ) : (
                  <div className="d-flex justify-content-between">
                    <span className="my-2 py-1"></span>
                    <Link to="/forgot-password">
                      <span
                        className="forgot-password d-flex justify-content-end italic my-2"
                        style={{ fontSize: "14px" }}
                      >
                        Forgot password?
                      </span>
                    </Link>
                  </div>
                )}
              </div>

              <button
                disabled={loading}
                type="submit"
                className="submit-button btn btn-primary w-100 border-0 fw-bold fs-5 my-2 rounded-3 py-2"
              >
                <p className="p-0 m-0">{loading ? "Loading..." : "Login"}</p>
              </button>
            </form>
            <div
              className="d-flex justify-content-center last"
              style={{ fontSize: "14px" }}
            >
              <span className="my-2 m-0 d-inline">
                Don't have an account? &nbsp;
              </span>
              <Link to="/create-new-account">
                <span className="forgot-password d-flex justify-content-center italic my-2">
                  Register
                </span>
              </Link>
            </div>
          </div>

          {/* Snackbar for successful registration
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity="info">
          User registered successfully!
        </Alert>
      </Snackbar> */}
        </div>
      )}
    </>
  );
};

export default LoginPage;
